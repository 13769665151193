
import { ComponentInternalInstance, defineComponent, getCurrentInstance, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import MetaDropdown from '../dropdown/index.vue'
import { languageList } from './data'
import store from '@/store'
import { getTransitionLanguage } from '@/locales'
import { find } from 'lodash'
import { useWindowSizeFn } from '@/hooks/useWindowSizeFn'

export default defineComponent({
  name: 'LangSelect',
  components: {
    MetaDropdown
  },
  emits: {
    onConfirm: (item: string | number | boolean | null) => item,
    // eslint-disable-next-line vue/return-in-emits-validator, @typescript-eslint/no-empty-function
    onCancel: () => { },
    'update:modelValue': (val: string | number | boolean | null) => val
  },
  setup(props, { emit }) {
    const { slots } = getCurrentInstance() as ComponentInternalInstance
    const lang = ref()
    const isSlots = ref(false)
    const router = useRouter()
    const placement = ref('bottom-end')

    watch(() => getTransitionLanguage(), (val) => {
      if (val) {
        const language = val
        // 使用 Lodash 的 find 函数查找数组中是否存在目标值
        const selectedLanguage = find(languageList, { value: language })
        lang.value = selectedLanguage && selectedLanguage.value ? selectedLanguage.value : 'en'
        emit('update:modelValue', lang.value)
        placement.value = val === 'ar' ? 'bottom-start' : 'bottom-end'
      }
    }, { immediate: true })

    useWindowSizeFn(
      () => {
        if (window.innerWidth > 825) {
          placement.value = getTransitionLanguage() === 'ar' ? 'bottom-start' : 'bottom-end'
        } else {
          placement.value = 'top-start'
        }
      },
      200,
      { immediate: true }
    )

    watch(() => slots?.content, (val) => {
      if (val) {
        isSlots.value = true
      }
    }, { immediate: true })

    const handleChooseLanguage = () => {
      store.commit('app/SET_LANG', lang.value)
      emit('update:modelValue', lang.value)
      emit('onConfirm', lang.value)
      router.go(0)
    }

    const handleCancelLanguage = () => {
      emit('onCancel')
    }

    return {
      languageList,
      lang,
      isSlots,
      placement,
      handleChooseLanguage,
      handleCancelLanguage
    }
  }
})
