import { App } from 'vue'
import { VXETable, Column, Table, Pager, Select, Option } from 'vxe-table'
import zhCN from 'vxe-table/lib/locale/lang/zh-CN'
import XEUtils from 'xe-utils'
import 'vxe-table/lib/style.css'

// 按需加载的方式默认是不带国际化的，自定义国际化需要自行解析占位符 '{0}'，例如：
VXETable.setup({
  i18n: (key, args) => XEUtils.toFormatString(XEUtils.get(zhCN, key), args)
})

export function useTable(app: App) {
  app.use(Column)
    .use(Table)
    .use(Pager)
    .use(Select)
    .use(Option)
}
