/**
 * 接口错误处理
 * Author: Huangzhh
 */

import { IAxiosError } from './interface'

/**
 * @param data IAxiosError
 */
export default function (data: IAxiosError): Promise<IAxiosError> {
  // 登录过期，用户不存在
  const code: string = data.errInfo?.code
  if (code === '9006' || code === '9005' || code === '9004') {
    // TODO
  }
  return Promise.reject(data)
}
