<template>
  <svg :width="width" :height="width" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>ic_icon_back</title>
    <g id="页面-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="音乐上传编辑界面" transform="translate(-500.000000, -162.000000)">
        <g id="编组-7" transform="translate(500.000000, 160.000000)">
          <g id="ic_icon_back" transform="translate(0.000000, 2.000000)">
            <circle id="椭圆形" :fill="bgColor" cx="10" cy="10" r="10"></circle>
            <g id="ic_common_icon_back" transform="translate(2.777778, 2.777778)" :stroke="color" stroke-linecap="round"
              stroke-linejoin="round" stroke-width="2">
              <polyline id="路径-30" :points="direction === 'left' ? '8.61111111 3.61111111 5 7.22222222 8.61111111 10.8333333'
                : '5 10.8333333 9.61111111 7.22222222 5 3.61111111'">
              </polyline>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'XySvgBack',
  props: {
    bgColor: {
      type: String as PropType<string>,
      default: '#5398FF'
    },
    color: {
      type: String as PropType<string>,
      default: '#FFFFFF'
    },
    direction: {
      type: String as PropType<'left' | 'right'>,
      default: 'left'
    },
    width: {
      type: String,
      default: '20px'
    },
    height: {
      type: String,
      default: '20px'
    }
  }
})
</script>
