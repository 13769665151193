<template>
  <footer class="m-footer">
    <slot></slot>
  </footer>
</template>

<script lang="ts">
export default {
  name: 'MFooter'
}
</script>

<style lang="scss" scoped>
@include b(m-footer) {
  position: fixed;
  bottom: 0px;
  width: 100%;
}
</style>
