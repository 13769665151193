<template>
  <main class="m-content">
    <slot></slot>
  </main>
</template>

<script lang="ts">
export default {
  name: 'MContent'
}
</script>

<style lang="scss" scoped>
@include b(m-content) {
  overflow: auto;
  flex: 1;
}
</style>
