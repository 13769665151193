import router from '@/router/index'
import { Toast } from 'vant'
import { RouteLocationNormalized, NavigationGuardNext } from 'vue-router'
import store from './store'
import { isIpad, isMobile } from './utils/is'
import { errorCodeLocalesV2 } from './utils/locales'
import { setAndroidBridgeTitle } from './utils/bridge'
import { setMetaTags } from './utils/metaUtils'
import i18n from '@/locales/index'

router.beforeEach(async (to: RouteLocationNormalized, _: RouteLocationNormalized, next: NavigationGuardNext) => {
  if (to.meta.title) {
    // 如果设置标题，拦截后设置标题
    const prefix = process.env.VUE_APP_USE === 'company' ? `${i18n.global.t('webMeta.titleCompany')}` : i18n.global.t('webMeta.title')
    setAndroidBridgeTitle(prefix, false)
  }

  setMetaTags(
    {
      description: process.env.VUE_APP_USE === 'company' ? i18n.global.t('webMeta.descriptionCompany') : i18n.global.t('webMeta.description'),
      keywords: process.env.VUE_APP_USE === 'company' ? i18n.global.t('webMeta.keywordsCompany') : i18n.global.t('webMeta.keywords')
    })

  // await store.dispatch('user/getUserInfo')
  // if (to.path === '/music' || to.path === '/upload') {
  //   // 移动端不展示music、upload路由
  //   if (isMobile() && !isIpad()) {
  //     next({ path: '/' })
  //     return
  //   }
  //   if (store.state.user.token && store.state.user.userInfo.uid) {
  //     const verifyInfo = {
  //       token: store.state.user.token,
  //       uid: store.state.user.userInfo.uid
  //     }
  //     await store.dispatch('user/getUserInfoByToken', verifyInfo).then().catch(err => {
  //       Toast.fail(errorCodeLocales(err.errInfo.code) as string)
  //       console.error(err)
  //     })
  //   }
  //   if (store.state.user.token && store.state.user.userInfo) {
  //     next()
  //   } else {
  //     //  如果未登录，重定向到登陆
  //     next({ path: '/login', query: { redirect: to.fullPath } })
  //     // next({ path: '/login' })
  //   }
  // } else {
  //   if (store.state.user.token && store.state.user.userInfo) {
  //     if (to.path === '/login') {
  //       //  如果已登录，重定向到主页
  //       next('/music')
  //     } else {
  //       next()
  //     }
  //   } else {
  //     next()
  //   }
  // }
  // 设备端判断
  const isMobileDevice = isMobile() && !isIpad()

  // 检查设备端显示配置，默认显示在所有设备上
  const displayOnMobile = to.meta.displayOnMobile !== false // 默认为 true
  const displayOnDesktop = to.meta.displayOnDesktop !== false // 默认为 true

  if ((isMobileDevice && !displayOnMobile) || (!isMobileDevice && !displayOnDesktop)) {
    return next({ path: '/' }) // 如果当前设备不支持该路由，重定向到首页
  }

  if (to.meta.requiresAuth) {
    await store.dispatch('user/getUserInfo')
    if (store.state.user.token && store.state.user.userInfo.uid) {
      const verifyInfo = {
        token: store.state.user.token,
        uid: store.state.user.userInfo.uid
      }
      await store.dispatch('user/getUserInfoByToken', verifyInfo).then().catch(err => {
        Toast.fail(errorCodeLocalesV2(err.errInfo.code) as string)
        console.error(err)
      })
    }
    if (store.state.user.token && store.state.user.userInfo) {
      return next()
    } else {
      //  如果未登录，重定向到登陆
      return next({ path: '/login', query: { redirect: to.fullPath } })
    }
  }
  next()
})
