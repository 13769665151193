import config from '../config'
import server from '../axiosInstance'
import { ContentType } from '../types/headers'
const { transponderURL } = config

/**
 * 获取用户IP当前位置
 * @param
 * @returns
 */
export const getUserLocationIP = (): Promise<any> => {
  return server.request<null, any>({
    url: `${transponderURL}/v2/local/ip`,
    method: 'GET',
    headers: {
      'Content-Type': ContentType.TEXT
    }
  })
}
