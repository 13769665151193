<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 48 48"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <rect id="path-1" x="0" y="0" :width="width" :height="height" />
      <linearGradient
        x1="50%"
        y1="90.3834587%"
        x2="70.4828369%"
        y2="32.9989735%"
        id="linearGradient-3"
      >
        <stop stop-color="#FFA66F" offset="0%" />
        <stop stop-color="#FFC76F" stop-opacity="0.721563593" offset="100%" />
      </linearGradient>
      <linearGradient
        x1="47.6466448%"
        y1="22.5644032%"
        x2="50%"
        y2="68.5004426%"
        id="linearGradient-4"
      >
        <stop stop-color="#FFEB6B" offset="0%" />
        <stop stop-color="#FFC400" offset="100%" />
      </linearGradient>
      <path
        d="M19,1.42108547e-14 C29.4934102,1.42108547e-14 38,7.163444 38,16 C38,24.836556 29.4934102,32 19,32 C16.2902057,32 13.7129059,31.5222934 11.3803778,30.6614294 L5.53910774,33.0160286 C4.51468993,33.4291169 3.34936099,32.9335364 2.93627269,31.9091186 C2.83489379,31.657709 2.78568178,31.3883143 2.79162612,31.1172993 L2.93504847,24.5468637 C1.07613588,22.0742609 0,19.1429599 0,16 C0,7.163444 8.50658975,1.42108547e-14 19,1.42108547e-14 Z"
        id="path-5"
      />
    </defs>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(-27.000000, -889.000000)">
        <g transform="translate(0.000000, 851.000000)">
          <g transform="translate(27.000000, 38.000000)">
            <mask fill="white">
              <use xlink:href="#path-1" />
            </mask>
            <g id="蒙版" />
            <g mask="url(#mask-2)">
              <g transform="translate(-1.000000, -2.000000)">
                <path
                  d="M12.3105799,17.9805799 L7.75207585,20.0669117 C7.24988883,20.2967521 6.6564633,20.0759716 6.42662282,19.5737846 C6.35064979,19.4077883 6.32156624,19.224162 6.34252521,19.0428132 L6.91808943,14.0627062 L6.91808943,14.0627062 L3.52521478,10.3720233 C3.15143918,9.96543963 3.17803533,9.33283349 3.58461895,8.95905788 C3.71901387,8.83550755 3.88466555,8.7511038 4.06361522,8.71499709 L8.9778375,7.72345352 L8.9778375,7.72345352 L11.4394297,3.35615421 C11.7106107,2.87503124 12.3204735,2.70483964 12.8015965,2.97602062 C12.9606302,3.06565863 13.0920921,3.19712055 13.1817301,3.35615421 L15.6433223,7.72345352 L15.6433223,7.72345352 L20.5575445,8.71499709 C21.0989192,8.82423036 21.449239,9.35165231 21.3400058,9.89302699 C21.3038991,10.0719767 21.2194953,10.2376283 21.095945,10.3720233 L17.7030703,14.0627062 L17.7030703,14.0627062 L18.2786345,19.0428132 C18.3420415,19.591446 17.9486882,20.0876023 17.4000554,20.1510093 C17.2187065,20.1719682 17.0350802,20.1428847 16.8690839,20.0669117 L12.3105799,17.9805799 L12.3105799,17.9805799 Z"
                  id="星形"
                  stroke="none"
                  fill="#FF8400"
                  fill-rule="evenodd"
                  transform="translate(12.310580, 12.310580) rotate(-11.000000) translate(-12.310580, -12.310580) "
                />
                <path
                  d="M13,20.7801097 C14.1045695,20.7801097 15,21.6755402 15,22.7801097 L14.9995,24.2798879 L16.5,24.2801097 C17.6045695,24.2801097 18.5,25.1755402 18.5,26.2801097 C18.5,27.3846792 17.6045695,28.2801097 16.5,28.2801097 L14.9995,28.2798879 L15,29.7801097 C15,30.8846792 14.1045695,31.7801097 13,31.7801097 C11.8954305,31.7801097 11,30.8846792 11,29.7801097 L10.9995,28.2798879 L9.5,28.2801097 C8.3954305,28.2801097 7.5,27.3846792 7.5,26.2801097 C7.5,25.1755402 8.3954305,24.2801097 9.5,24.2801097 L10.9995,24.2798879 L11,22.7801097 C11,21.6755402 11.8954305,20.7801097 13,20.7801097 Z"
                  id="形状结合"
                  stroke="none"
                  fill="#272727"
                  fill-rule="evenodd"
                />
                <path
                  d="M33,27.7801097 C34.1045695,27.7801097 35,28.6755402 35,29.7801097 C35,30.8846792 34.1045695,31.7801097 33,31.7801097 C31.8954305,31.7801097 31,30.8846792 31,29.7801097 C31,28.6755402 31.8954305,27.7801097 33,27.7801097 Z M29,23.7801097 C30.1045695,23.7801097 31,24.6755402 31,25.7801097 C31,26.8846792 30.1045695,27.7801097 29,27.7801097 C27.8954305,27.7801097 27,26.8846792 27,25.7801097 C27,24.6755402 27.8954305,23.7801097 29,23.7801097 Z M37,23.7801097 C38.1045695,23.7801097 39,24.6755402 39,25.7801097 C39,26.8846792 38.1045695,27.7801097 37,27.7801097 C35.8954305,27.7801097 35,26.8846792 35,25.7801097 C35,24.6755402 35.8954305,23.7801097 37,23.7801097 Z M33,19.7801097 C34.1045695,19.7801097 35,20.6755402 35,21.7801097 C35,22.8846792 34.1045695,23.7801097 33,23.7801097 C31.8954305,23.7801097 31,22.8846792 31,21.7801097 C31,20.6755402 31.8954305,19.7801097 33,19.7801097 Z"
                  id="形状结合"
                  stroke="none"
                  fill="#272727"
                  fill-rule="evenodd"
                />
                <path
                  d="M29,15.1630083 C39.4934102,15.1630083 48,22.3264523 48,31.1630083 C48,39.9995643 39.4934102,47.1630083 29,47.1630083 C26.2902057,47.1630083 23.7129059,46.6853018 21.3803778,45.8244378 L15.5391077,48.1790369 C14.5146899,48.5921252 13.349361,48.0965447 12.9362727,47.0721269 C12.8348938,46.8207174 12.7856818,46.5513226 12.7916261,46.2803077 L12.9350485,39.709872 C11.0761359,37.2372693 10,34.3059683 10,31.1630083 C10,22.3264523 18.5065898,15.1630083 29,15.1630083 Z"
                  id="形状结合"
                  stroke="none"
                  fill="url(#linearGradient-3)"
                  fill-rule="evenodd"
                />
                <g
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                  transform="translate(6.000000, 11.163008)"
                >
                  <mask fill="white">
                    <use xlink:href="#path-5" />
                  </mask>
                  <use fill="url(#linearGradient-4)" xlink:href="#path-5" />
                  <ellipse
                    fill="#FFB400"
                    mask="url(#mask-6)"
                    cx="39.7595086"
                    cy="36"
                    rx="32"
                    ry="26"
                  />
                </g>
                <rect
                  stroke="none"
                  fill="#272727"
                  fill-rule="evenodd"
                  transform="translate(24.759509, 27.163008) rotate(45.000000) translate(-24.759509, -27.163008) "
                  x="21.7595086"
                  y="24.1630083"
                  width="6"
                  height="6"
                  rx="3"
                />
                <rect
                  stroke="none"
                  fill="#272727"
                  fill-rule="evenodd"
                  transform="translate(14.759509, 27.163008) rotate(45.000000) translate(-14.759509, -27.163008) "
                  x="11.7595086"
                  y="24.1630083"
                  width="6"
                  height="6"
                  rx="3"
                />
                <rect
                  stroke="none"
                  fill="#272727"
                  fill-rule="evenodd"
                  transform="translate(34.759509, 27.163008) rotate(45.000000) translate(-34.759509, -27.163008) "
                  x="31.7595086"
                  y="24.1630083"
                  width="6"
                  height="6"
                  rx="3"
                />
                <path
                  d="M25,11.1630083 C35.4934102,11.1630083 44,18.3264523 44,27.1630083 C44,35.9995643 35.4934102,43.1630083 25,43.1630083 C22.2902057,43.1630083 19.7129059,42.6853018 17.3803778,41.8244378 L11.5391077,44.1790369 C10.5146899,44.5921252 9.34936099,44.0965447 8.93627269,43.0721269 C8.83489379,42.8207174 8.78568178,42.5513226 8.79162612,42.2803077 L8.93504847,35.709872 C7.07613588,33.2372693 6,30.3059683 6,27.1630083 C6,18.3264523 14.5065898,11.1630083 25,11.1630083 Z"
                  id="形状结合"
                  stroke="#272727"
                  stroke-width="2"
                  fill="none"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'YbSvgBook',
  props: {
    colors: {
      type: Array as PropType<string[]>,
      default: () => {
        return []
      }
    },
    width: {
      type: String,
      default: '48'
    },
    height: {
      type: String,
      default: '48'
    }
  }
})
</script>
