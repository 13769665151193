import config from '../config'
import { IAxiosSuccess } from '../interface'
import server from '../axiosInstance'
import { filterDataEmpty, getSignedData, getTimestamp, randomNum } from '../utils'
import { ContentType } from '../types/headers'
import { UidByUserInfoType, UserInfoType } from '../types/users'
const { loginURL, homeLoginSecret } = config

/**
 * 用户邮箱登陆
 * @param userName 邮箱
 * @param passWord 密码
 * @returns
 */
export const loginEmailByPwd = (userName: string, passWord: string): Promise<IAxiosSuccess<UserInfoType>> => {
  const timestamp = getTimestamp()
  const random = randomNum(11)
  const data = {
    sign: '',
    appId: 1004,
    timestamp,
    random,
    passWord,
    userName
  }
  data.sign = getSignedData(data, homeLoginSecret)
  filterDataEmpty(data)
  return server.request<null, IAxiosSuccess<UserInfoType>>({
    url: `${loginURL}/v1/user/loginByEmailOrUserName`,
    method: 'POST',
    headers: {
      'Content-Type': ContentType.JSON,
      pf: 3
    },
    data
  })
}

/**
 * 验证token并返回用户信息
 * @param token 登陆凭证
 * @param uid 用户uid
 * @returns
 */
export const getUserInfoByToken = (token: string, uid: string): Promise<IAxiosSuccess<UserInfoType>> => {
  const timestamp = getTimestamp()
  const random = randomNum(11)
  const params = {
    sign: '',
    appId: 1004,
    timestamp,
    random
  }
  params.sign = getSignedData(params, homeLoginSecret)
  filterDataEmpty(params)
  return server.request<null, IAxiosSuccess<UserInfoType>>({
    url: `${loginURL}/v1/user/getUserInfoByToken`,
    method: 'GET',
    headers: {
      'Content-Type': ContentType.JSON,
      token,
      uid
    },
    params
  })
}

/**
 * 验证uid并返回用户信息
 * @param token 登陆凭证
 * @param uid 用户uid
 * @returns
 */
export const getUserInfoByUid = (uid: string): Promise<IAxiosSuccess<UidByUserInfoType>> => {
  return server.request<null, IAxiosSuccess<UidByUserInfoType>>({
    url: `${loginURL}/v1/webPage/getUserInfoByUid`,
    method: 'GET',
    headers: {
      'Content-Type': ContentType.JSON,
      uid
    }
  })
}
