export default {
  2: 'Tham số sai',
  2000: 'Tham số sai',
  2001: 'Cảnh không tồn tại',
  2002: 'Cảnh đã được gỡ bỏ khỏi kệ',
  9001: 'Máy chủ đã ngừng hoạt động, vui lòng thử lại.',
  10004: 'Đăng nhập người dùng không thành công',
  10007: 'Tài khoản hoặc mật khẩu không chính xác',
  10008: 'Người dùng không tồn tại',
  10010: 'Nội dung email không được gửi đi',
  10019: 'Lịch sử thay đổi mật khẩu email không tồn tại',
  10020: 'Liên kết đặt lại mật khẩu đã hết hạn',
  10021: 'Liên kết mật khẩu đã được đặt lại',
  10022: 'Đặt lại mật khẩu không thành công',
  10025: 'Mật khẩu trống',
  10030: 'Đăng nhập từ các thiết bị khác',
  10037: 'Mã thông báo đã hết hạn',
  10039: 'Không thể tìm thấy thông tin mã lời mời',
  10040: 'Không thể lấy được thông tin mã lời mời',
  10043: 'Đã đăng xuất',
  10046: 'Đơn hàng không tồn tại',
  10047: 'Không tạo được đơn hàng',
  10048: 'Cấu hình đơn hàng không tồn tại',
  10064: 'Người dùng bị cấm',
  10091: 'Số lượng đơn hàng chưa thanh toán đã vượt quá 20',
  70000: 'Tham số sai',
  70001: 'Thông tin nạp tiền không tồn tại hoặc đã bị gỡ khỏi kệ'
}
