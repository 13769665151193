
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'XySvgUpload',
  props: {
    color: {
      type: String as PropType<string>,
      default: '#FFFFFF'
    },
    width: {
      type: String,
      default: '28'
    },
    height: {
      type: String,
      default: '28'
    }
  }
})
