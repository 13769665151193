
import { defineComponent, reactive, toRefs } from 'vue'
import { event } from 'vue-gtag'
import { Button } from 'vant'
import UaParser from 'ua-parser-js'
import { isIpad } from '@/utils/is'
import { OpenUrlEnum } from '@/types/url-enum'
import { openWindow } from '@/utils'

interface IMDownloadState {
  imgMaxWidth: string
}
export default defineComponent({
  name: 'MDownloadV2',
  components: {
    [Button.name]: Button
  },
  setup() {
    const state = reactive<IMDownloadState>({
      imgMaxWidth: '165px'
    })

    // 根据系统跳去对应商店
    const onClickToPath = () => {
      const uaInfo = new UaParser().getResult()
      if (uaInfo.os.name === 'Android') {
        // 跳转去google Play时上报打点
        event('web_home_jump_android', {
          event_category: 'web_home',
          to_path: OpenUrlEnum.GOOGLE_PLAY,
          jump_source: 'google_play'
        })
        openWindow(OpenUrlEnum.GOOGLE_PLAY, { target: '_blank' })
      } else if (uaInfo.os.name === 'iOS' || uaInfo.os.name === 'Mac OS' || isIpad()) {
        // 跳转去苹果商店
        event('web_home_jump_ios', {
          event_category: 'web_home',
          to_path: OpenUrlEnum.IOS_APP_STORE,
          jump_source: 'apple_store'
        })
        openWindow(OpenUrlEnum.IOS_APP_STORE, { target: '_blank' })
      } else {
        // 跳转去google Play时上报打点
        event('web_home_jump_android', {
          event_category: 'web_home',
          to_path: OpenUrlEnum.GOOGLE_PLAY,
          jump_source: 'unknown'
        })
        openWindow(OpenUrlEnum.GOOGLE_PLAY, { target: '_blank' })
      }
    }

    return {
      ...toRefs(state),
      onClickToPath
    }
  }
})
