export default {
  2: 'Parameternya salah',
  2000: 'Parameternya salah',
  2001: 'Adegan tidak ada',
  2002: 'Adegan telah ditarik dari rak',
  9001: 'Server diskros, coba lagi',
  10004: 'Pendaratan pengguna gagal',
  10007: 'Nomor rekening atau kata sandi yang salah',
  10008: 'Pengguna tidak ada',
  10010: 'Pengiriman pesan gagal',
  10019: 'Email modifikasi catatan sandi tidak ada',
  10020: 'Reset koneksi kata sandi telah habis',
  10021: 'Koneksi password telah disetel ulang',
  10022: 'Gagal untuk mengatur ulang kata sandi',
  10025: 'Password kosong',
  10030: 'Log masuk di perangkat lainnya',
  10037: 'Token telah kedaluwarsa',
  10039: 'Tidak dapat menemukan kode undangan',
  10040: 'Dapatkan kode undangan gagal',
  10043: 'Sudah keluar masuk',
  10046: 'Pesanan tidak ada',
  10047: 'Gagal membuat pesanan',
  10048: 'Konfigurasi pesanan tidak ada',
  10064: 'Pengguna dilarang',
  10091: 'Pesanan tunggal belum dibayar melebihi 20',
  70000: 'Parameternya salah',
  70001: 'Informasi top tidak ada atau keluar dari rak'
}
