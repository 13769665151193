<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 48 48"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>ic_home_icon_play</title>
    <defs>
      <rect x="0" y="0" :width="width" :height="height" />
      <linearGradient
        x1="50%"
        y1="75.8774343%"
        x2="70.4828369%"
        y2="39.1058627%"
        id="linearGradient-3"
      >
        <stop stop-color="#FFA66F" offset="0%" />
        <stop stop-color="#FFC76F" stop-opacity="0.721563593" offset="100%" />
      </linearGradient>
      <linearGradient
        x1="47.6466448%"
        y1="32.6689517%"
        x2="50%"
        y2="61.6867173%"
        id="linearGradient-4"
      >
        <stop stop-color="#FFEB6B" offset="0%" />
        <stop stop-color="#FFC400" offset="100%" />
      </linearGradient>
      <path
        d="M41.6438184,13.4219935 C43.0021302,21.6596148 40.3563709,29.0440238 35.7343496,29.9155327 C32.3899204,30.5461443 28.9271648,27.5939623 26.753786,22.792887 C24.8362424,21.8445549 22.9181212,21.3708273 21,21.3708273 C19.0818788,21.3708273 17.1637576,21.8445549 15.2456364,22.7920101 C13.0728352,27.5939623 9.61007956,30.5461443 6.26565035,29.9155327 C1.64362912,29.0440238 -1.00213018,21.6596148 0.356181571,13.4219935 C1.71449332,5.18437222 6.56251109,-0.787041673 11.1845323,0.0844672589 C13.1499262,0.455053685 14.7579816,2.00327252 15.8527862,4.29396726 C17.5685202,5.05429212 19.2842601,5.43332729 21,5.43332729 C22.7157399,5.43332729 24.4314798,5.05429212 26.1472197,4.29622177 C27.2420184,2.00327252 28.8500738,0.455053685 30.8154677,0.0844672589 C35.4374889,-0.787041673 40.2855067,5.18437222 41.6438184,13.4219935 Z"
        id="path-5"
      />
    </defs>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(-30.000000, -3011.000000)">
        <g transform="translate(0.000000, 2974.000000)">
          <g transform="translate(30.000000, 37.000000)">
            <mask fill="white">
              <use xlink:href="#path-1" />
            </mask>
            <g />
            <g mask="url(#mask-2)">
              <g transform="translate(1.000000, -0.836992)">
                <path
                  d="M45.8632698,28.1383453 C47.3018184,36.9251413 44.4997714,44.8018443 39.6047227,45.7314539 C36.0628747,46.4040796 32.3957206,43.2553611 30.0939411,38.1345728 C28.0628787,37.1224172 26.0314393,36.6171014 24,36.6171014 C21.9685607,36.6171014 19.9371213,37.1224172 17.905682,38.1330488 C15.6042794,43.2553611 11.9371253,46.4040796 8.39527733,45.7314539 C3.5002286,44.8018443 0.698181617,36.9251413 2.13673022,28.1383453 C3.57527883,19.3515493 8.70967393,12.9820412 13.6047227,13.9116507 C15.6860864,14.3069186 17.3890498,15.9581733 18.5485213,18.4013334 C20.3654102,19.2127062 22.1827051,19.6171014 24,19.6171014 C25.8172949,19.6171014 27.6345898,19.2127062 29.4518846,18.4039158 C30.6109502,15.9581733 32.3139136,14.3069186 34.3952773,13.9116507 C39.2903261,12.9820412 44.4247212,19.3515493 45.8632698,28.1383453 Z"
                  id="形状结合"
                  stroke="none"
                  fill="url(#linearGradient-3)"
                  fill-rule="evenodd"
                />
                <path
                  d="M15.126817,13.4129861 L17.6854031,5.7372279 C17.8600509,5.21328459 18.4263708,4.9301246 18.9503142,5.10477237 C19.0962791,5.15342736 19.2290483,5.23510771 19.3382892,5.34345675 L21.7405849,7.72614111 L21.7405849,7.72614111 L25.4987892,4.66337392 C25.9269103,4.31447453 26.5568098,4.37869639 26.9057092,4.80681747 C27.1178026,5.06706913 27.1851472,5.41638329 27.0850074,5.7368307 L24.5046431,13.9939967 C24.3399103,14.5211414 23.7790328,14.8149344 23.2518881,14.6502017 C23.069434,14.5931848 22.9071446,14.485068 22.7842438,14.3386584 L20.4595392,11.5692784 L20.4595392,11.5692784 L16.6916506,14.5168425 C16.2566554,14.8571329 15.6281622,14.7803595 15.2878717,14.3453642 C15.081248,14.0812359 15.0207712,13.7311235 15.126817,13.4129861 Z"
                  id="路径-8"
                  stroke="none"
                  fill="#FF8400"
                  fill-rule="evenodd"
                  transform="translate(21.077086, 9.562178) rotate(-30.000000) translate(-21.077086, -9.562178) "
                />
                <g
                  id="路径-4"
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                  transform="translate(0.000000, 11.617101)"
                >
                  <mask id="mask-6" fill="white">
                    <use xlink:href="#path-5" />
                  </mask>
                  <use id="蒙版" fill="url(#linearGradient-4)" xlink:href="#path-5" />
                  <path
                    d="M4,28.5342746 C6.94749009,27.7188124 9.07494004,25.9166443 10.3823499,23.1277704 C11.6897597,20.3388964 12.198896,18.3535493 11.9097589,17.1717292 C14.3949418,16.3905764 17.4995386,16 21.2235493,16 C24.9475599,16 28.0509021,16.3905764 30.5335759,17.1717292 C30.6795553,19.0498258 31.1815515,21.0351728 32.0395644,23.1277704 C33.3265839,26.2666667 36.274911,28.5342746 38.4470985,28.5342746 C39.8952236,28.5342746 38.4987196,29.0228497 34.2575865,30 L8.18951198,30 L4,28.5342746 Z"
                    fill="#FFAD00"
                    mask="url(#mask-6)"
                  />
                </g>
                <path
                  d="M11,19.6171014 C12.1045695,19.6171014 13,20.5125319 13,21.6171014 L12.9995,23.1168796 L14.5,23.1171014 C15.6045695,23.1171014 16.5,24.0125319 16.5,25.1171014 C16.5,26.2216709 15.6045695,27.1171014 14.5,27.1171014 L12.9995,27.1168796 L13,28.6171014 C13,29.7216709 12.1045695,30.6171014 11,30.6171014 C9.8954305,30.6171014 9,29.7216709 9,28.6171014 L8.9995,27.1168796 L7.5,27.1171014 C6.3954305,27.1171014 5.5,26.2216709 5.5,25.1171014 C5.5,24.0125319 6.3954305,23.1171014 7.5,23.1171014 L8.9995,23.1168796 L9,21.6171014 C9,20.5125319 9.8954305,19.6171014 11,19.6171014 Z"
                  id="形状结合"
                  stroke="none"
                  fill="#272727"
                  fill-rule="evenodd"
                />
                <path
                  d="M31,26.6171014 C32.1045695,26.6171014 33,27.5125319 33,28.6171014 C33,29.7216709 32.1045695,30.6171014 31,30.6171014 C29.8954305,30.6171014 29,29.7216709 29,28.6171014 C29,27.5125319 29.8954305,26.6171014 31,26.6171014 Z M27,22.6171014 C28.1045695,22.6171014 29,23.5125319 29,24.6171014 C29,25.7216709 28.1045695,26.6171014 27,26.6171014 C25.8954305,26.6171014 25,25.7216709 25,24.6171014 C25,23.5125319 25.8954305,22.6171014 27,22.6171014 Z M35,22.6171014 C36.1045695,22.6171014 37,23.5125319 37,24.6171014 C37,25.7216709 36.1045695,26.6171014 35,26.6171014 C33.8954305,26.6171014 33,25.7216709 33,24.6171014 C33,23.5125319 33.8954305,22.6171014 35,22.6171014 Z M31,18.6171014 C32.1045695,18.6171014 33,19.5125319 33,20.6171014 C33,21.7216709 32.1045695,22.6171014 31,22.6171014 C29.8954305,22.6171014 29,21.7216709 29,20.6171014 C29,19.5125319 29.8954305,18.6171014 31,18.6171014 Z"
                  id="形状结合"
                  stroke="none"
                  fill="#272727"
                  fill-rule="evenodd"
                />
                <path
                  d="M41.6530073,25.0390949 C42.9762771,33.2767161 40.3987735,40.6611252 35.8959919,41.5326341 C32.528149,42.1844768 29.0371396,39.0082171 26.9375803,33.9188462 C24.3696356,33.1142404 22.2238198,32.7123395 20.5,32.7123395 C18.8923507,32.7123395 17.101184,33.0618964 15.1264999,33.7610102 C13.0345049,38.942641 9.50640315,42.1911643 6.10400808,41.5326341 C1.6012265,40.6611252 -0.976277059,33.2767161 0.34699274,25.0390949 C1.67026254,16.8014736 6.39321035,10.8300597 10.8959919,11.7015686 C12.6037358,12.0321008 14.0345452,13.2994572 15.080649,15.1948801 C16.8867813,16.0804314 18.6933907,16.5218633 20.5,16.5218633 C22.4884868,16.5218633 24.6481174,16.0444787 26.9788916,15.0897097 C28.0200623,13.2515169 29.4287926,12.0258049 31.1040081,11.7015686 C35.6067897,10.8300597 40.3297375,16.8014736 41.6530073,25.0390949 Z"
                  id="形状结合"
                  stroke="#272727"
                  stroke-width="2"
                  fill="none"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'YbSvgBook',
  props: {
    colors: {
      type: Array as PropType<string[]>,
      default: () => {
        return []
      }
    },
    width: {
      type: String,
      default: '48'
    },
    height: {
      type: String,
      default: '48'
    }
  }
})
</script>
