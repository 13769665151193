<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 48 48"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>ic_common_icon_party</title>
    <defs>
      <linearGradient
        x1="50%"
        y1="83.2756233%"
        x2="70.4828369%"
        y2="35.9913001%"
        id="linearGradient-1"
      >
        <stop stop-color="#FFA66F" offset="0%" />
        <stop stop-color="#FFC76F" stop-opacity="0.721563593" offset="100%" />
      </linearGradient>
      <linearGradient
        x1="47.6466448%"
        y1="18.4141644%"
        x2="50%"
        y2="71.2990423%"
        id="linearGradient-2"
      >
        <stop stop-color="#FFEB6B" offset="0%" />
        <stop stop-color="#FFC400" offset="100%" />
      </linearGradient>
    </defs>
    <g id="落地页" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(-27.000000, -1654.000000)" id="游戏简介">
        <g transform="translate(0.000000, 1616.000000)">
          <g id="ic_common_icon_party" transform="translate(27.000000, 38.000000)">
            <path
              d="M22.1875,9 C28.0479847,9 32.7428114,9.24221465 36.27198,9.72664396 C37.2621696,9.86256176 38.0000107,10.7086008 38,11.7080752 L38,11.7080752 L37.9990712,13.9713958 C38.6565128,13.9904653 39.323489,14 40,14 L44,14 L44,14 C45.1045695,14 46,14.8954305 46,16 L46,38 C46,39.1045695 45.1045695,40 44,40 L40,40 L40,40 C36.6614489,40 33.5551045,39.7677933 30.6809668,39.3033798 C29.7121126,39.1468248 29,38.3104101 29,37.3289886 L29.0009415,35.1134551 C27.1888405,35.0378184 25.2301933,35 23.125,35 L23.125,35 L8,35 L8,9 L8,9 Z"
              id="形状结合"
              fill="url(#linearGradient-1)"
            />
            <path
              d="M28,9 C30,9.66666667 33,10 37,10 C38.3333333,10 39.6666667,10 41,10 C42.1045695,10 43,10.8954305 43,12 L43,34 C43,35.1045695 42.1045695,36 41,36 C39.6666667,36 38.3333333,36 37,36 C33.9520725,36 31.4847614,35.8064612 29.5980666,35.4193836 C28.6677951,35.2285459 28,34.4098354 28,33.4601912 L28,9 L28,9 Z"
              id="Rectangle-14"
              fill="#FF8A00"
            />
            <rect id="Rectangle-15" fill="#272727" x="5" y="2" width="4" height="45" rx="2" />
            <path
              d="M5,41 L9,41 C10.6568542,41 12,42.3431458 12,44 L12,46 C12,46.5522847 11.5522847,47 11,47 L3,47 C2.44771525,47 2,46.5522847 2,46 L2,44 C2,42.3431458 3.34314575,41 5,41 Z"
              id="Rectangle-15"
              fill="#272727"
            />
            <path
              d="M35,33 L28.7783015,35.038599 C28.5991854,34.912311 28.4370499,34.7299076 28.2918948,34.4913888 C28.1467397,34.2528699 28.0494414,33.9657739 28,33.6301007 L28,31 L35,31 L35,33 Z"
              id="路径-19"
              fill="#E76601"
            />
            <path
              d="M7,5 L7,31 C9.5,31 13.875,31 20.125,31 C26.375,31 31.3333333,31.3333333 35,32 L35,7.70807521 C35.0000107,6.70860081 34.2621696,5.86256176 33.27198,5.72664396 C29.7428114,5.24221465 25.0479847,5 19.1875,5 C12.3125,5 8.25,5 7,5 Z"
              id="路径-3"
              stroke="#272727"
              stroke-width="2"
              fill="url(#linearGradient-2)"
              stroke-linejoin="round"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'YbSvgWelfareParty',
  props: {
    colors: {
      type: Array as PropType<string[]>,
      default: () => {
        return []
      }
    },
    width: {
      type: String,
      default: '24'
    },
    height: {
      type: String,
      default: '24'
    }
  }
})
</script>
