<template>
  <svg :width="width" :height="height" viewBox="0 0 28 28" version="1.1" xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>uploading</title>
    <g id="页面-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round"
      stroke-linejoin="round">
      <g id="音乐上传列表" transform="translate(-1444.000000, -252.000000)" :stroke="color" stroke-width="2">
        <g id="编组-5" transform="translate(1284.000000, 244.000000)">
          <g id="编组-4" transform="translate(148.000000, 0.000000)">
            <g id="uploading" transform="translate(12.000000, 8.000000)">
              <g id="编组-2" transform="translate(7.200000, 7.144525)">
                <path
                  d="M14,8.60547543 L14,11.50093 C14,12.4147102 13.2164983,13.1554754 12.25,13.1554754 L1.75,13.1554754 C0.783501688,13.1554754 0,12.4147102 0,11.50093 L0,8.60547543"
                  id="路径"></path>
                <polyline id="路径"
                  transform="translate(6.970270, 2.828427) scale(1, -1) rotate(-45.000000) translate(-6.970270, -2.828427) "
                  points="8.97027027 4.81826624 4.97027027 4.82842712 4.98043115 0.828427125"></polyline>
                <line x1="6.975" y1="8.90547543" x2="6.975" y2="2.50547543" id="路径-9"
                  transform="translate(6.975000, 5.705475) scale(1, -1) translate(-6.975000, -5.705475) "></line>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'XySvgUpload',
  props: {
    color: {
      type: String as PropType<string>,
      default: '#FFFFFF'
    },
    width: {
      type: String,
      default: '28'
    },
    height: {
      type: String,
      default: '28'
    }
  }
})
</script>
