<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="28px" height="28px"
    viewBox="0 0 28 28" version="1.1">
    <title>Refresh</title>
    <g id="页面-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round"
      stroke-linejoin="round">
      <g id="音乐上传列表" transform="translate(-1308.000000, -252.000000)" stroke="#2F2F2F" stroke-width="2">
        <g id="编组-5" transform="translate(1284.000000, 244.000000)">
          <g id="Refresh" transform="translate(24.000000, 8.000000)">
            <g id="编组-7" transform="translate(4.000000, 6.000000)">
              <path
                d="M3.18725735,4 C2.43463755,5.20251371 2,6.62160455 2,8.14146913 C2,12.4816159 5.54426577,16 9.91633914,16 C11.0102287,16 12.0522977,15.7797503 13,15.3814865"
                id="路径" />
              <path
                d="M0,5.19917629 L3.08168855,4.03308564 C3.31827466,3.94356303 3.59147599,4.04196054 3.69190086,4.25286263 L5,7 L5,7"
                id="路径备份" />
              <path
                d="M8.18725736,0 C7.43463756,1.20251368 7,2.62160453 7,4.14146916 C7,8.48161588 10.5442658,12 14.9163391,12 C16.0102287,12 17.0522977,11.7797503 18,11.3814865"
                id="路径备份-2"
                transform="translate(12.500000, 6.000000) rotate(180.000000) translate(-12.500000, -6.000000) " />
              <path
                d="M20,11.8008237 L16.9183115,12.9669144 C16.6817254,13.056437 16.408524,12.9580395 16.3080992,12.7471374 L15,10 L15,10"
                id="路径备份-3" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'YbSvgRefresh',
  props: {
    colors: {
      type: Array as PropType<string[]>,
      default: () => {
        return []
      }
    },
    width: {
      type: String,
      default: '24'
    },
    height: {
      type: String,
      default: '24'
    }
  }
})
</script>
