<template>
  <div class="m-button">
    <slot></slot>
  </div>
</template>

<script lang="ts">
export default {
  name: 'MButton',
  props: {
    text: {
      type: String,
      require: true
    }
  }
}
</script>

<style lang="scss" scoped>
@include b(m-button) {
  @include box-center;
}
</style>
