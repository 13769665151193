import { getUserInfoByToken, getUserInfoByUid, loginEmailByPwd } from '@/api/request/users'
import { UidByUserInfoType, UserInfoType } from '@/api/types/users'
import { getToken, getUserInfo, removeToken, removeUserInfo, setToken, setUid, setUserInfo } from '@/utils/cookies'
import * as Sentry from '@sentry/browser'

export interface UserState {
  token: string
  userInfo: UserInfoType
}

const state: UserState = {
  token: '',
  userInfo: {} as UserInfoType
}

const mutations = {
  SET_TOKEN(state: UserState, token: string): void {
    state.token = token
  },
  SET_USERINFO(state: UserState, userInfo: UserInfoType): void {
    state.userInfo = userInfo
  }
}

const actions = {
  /** 用户登陆 */
  login({ commit, dispatch }: any, userInfo: { userName: string, passWord: string }): Promise<unknown> {
    return new Promise((resolve, reject) => {
      loginEmailByPwd(userInfo.userName, userInfo.passWord).then(res => {
        if (res && res.data) {
          /** token */
          setToken(res.data.token)
          commit('SET_TOKEN', res.data.token)

          /** 用户信息 */
          setUserInfo(JSON.stringify(res.data))
          commit('SET_USERINFO', res.data)
          // 设置sentry 用户信息
          Sentry.setUser({
            name: res.data.nickname,
            uid: res.data.uid,
            username: res.data.userName
          })
          resolve(res.data)
        }
      }).catch(error => {
        dispatch('loginOut')
        reject(error)
      })
    })
  },

  /** 退出登陆 */
  loginOut({ commit }: any) {
    commit('SET_TOKEN', '')
    commit('SET_USERINFO', {})

    removeToken()
    removeUserInfo()
    // 注销sentry 用户信息
    Sentry.setUser(null)
  },

  /** 获取用户信息 */
  getUserInfo({ commit }: any) {
    const userInfo = getUserInfo() ? (JSON.parse(getUserInfo() as string) as UserInfoType) : {} as UserInfoType
    const token = getToken() ? getToken() as string : ''
    setUserInfo(JSON.stringify(userInfo))
    commit('SET_USERINFO', userInfo)
    setToken(token)
    commit('SET_TOKEN', token)
  },

  /** 校验用户token, 并返回用户信息 */
  getUserInfoByToken({ commit, dispatch }: any, verifyInfo: { token: string, uid: string }): Promise<unknown> {
    return new Promise((resolve, reject) => {
      getUserInfoByToken(verifyInfo.token, verifyInfo.uid).then(res => {
        if (res && res.data) {
          /** token */
          setToken(verifyInfo.token)
          commit('SET_TOKEN', verifyInfo.token)

          /** 用户信息 */
          setUserInfo(JSON.stringify(res.data))
          commit('SET_USERINFO', res.data)
          resolve(res.data)
        }
      }).catch(error => {
        dispatch('loginOut')
        reject(error)
      })
    })
  },

  /** 校验用户uid, 并返回用户信息 */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getUserInfoByUid({ commit }: any, uid: string): Promise<unknown> {
    return new Promise((resolve: (value: UidByUserInfoType) => void, reject) => {
      getUserInfoByUid(uid).then(res => {
        if (res && res.data) {
          /** 用户uid */
          setUid(res.data.uid)
          resolve(res.data)
        }
      }).catch(error => {
        reject(error)
      })
    })
  }
}

export const user = {
  namespaced: true,
  state,
  mutations,
  actions
}
