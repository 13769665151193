import JSZip from 'jszip'

export const getAllFilesFromDirectory = (directory: any): Promise<File[]> => {
  const collectedFiles: File[] = []

  // 递归处理每个条目
  const processEntry = (entry: any, currentPath: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      if (entry.isFile) {
        // 处理文件条目
        const fileEntry = entry as any
        fileEntry.file((file: File) => {
          collectedFiles.push(createFileWithRelativePath(file, '', `${currentPath}/${file.name}`)) // 将文件添加到数组
          resolve()
        }, (error: any) => {
          console.error('读取文件失败:', error)
          resolve() // 忽略错误并继续处理其他条目
        })
      } else if (entry.isDirectory) {
        // 处理目录条目，递归读取文件夹
        const directoryEntry = entry as any
        readAllFilesInDirectory(directoryEntry, `${currentPath}/${entry.name}`).then(() => resolve()).catch(reject)
      } else {
        console.warn('不支持的条目类型:', entry)
        resolve() // 跳过不支持的条目
      }
    })
  }

  // 递归读取目录的所有条目
  const readAllFilesInDirectory = (dir: any, path: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      const reader = dir.createReader()
      const readEntries = () => {
        reader.readEntries((entries: any) => {
          if (entries.length === 0) {
            resolve() // 没有更多条目，递归结束
          } else {
            // 处理当前读取到的所有条目
            Promise.all(entries.map((entry: any) => processEntry(entry, path))).then(() => {
              readEntries() // 继续读取剩余的条目
            }).catch(reject)
          }
        }, (error: any) => {
          console.error('读取目录失败:', error)
          reject(error) // 读取目录时出错
        })
      }
      readEntries() // 开始读取条目
    })
  }

  // 从传入的目录开始递归读取
  return new Promise((resolve, reject) => {
    readAllFilesInDirectory(directory, directory.name || '').then(() => {
      resolve(collectedFiles) // 返回收集到的文件
    }).catch(reject)
  })
}

export const extractFilesAndFolders = (items: DataTransferItem[]): { files: File[], folders: DataTransferItem[] } => {
  const files: File[] = []
  const folders: DataTransferItem[] = []

  items.forEach(item => {
    const entry = item.webkitGetAsEntry()
    if (entry) {
      if (entry.isFile) {
        // 添加文件到 files 数组
        (entry as any).file((file: File) => {
          files.push(file)
        })
      } else if (entry.isDirectory) {
        // 将目录添加到 folders 数组
        folders.push(entry as any)
      }
    } else {
      console.warn('条目不支持 webkitGetAsEntry 方法:', item)
    }
  })

  return { files, folders }
}

// 当前时间戳加上9位随机数
export const generateUniqueId = (): number => {
  return Date.now() + Math.floor(Math.random() * 1000000000)
}

let isCancelled = false // 控制变量，用于检测用户是否取消压缩
export interface CompressOptions {
  newRootFolderName?: string // 可选的首层文件夹名称
}

/**
 * 压缩文件并保持文件夹层级结构
 * @param files - 需要压缩的文件数组
 * @param onProgress - 进度更新的回调函数
 * @returns 压缩后的 Blob 对象
 */
export const compressFiles = async (files: File[], onProgress?: (progress: number) => void, options?: CompressOptions): Promise<Blob> => {
  isCancelled = false
  try {
    const zip = new JSZip()

    // 遍历文件，并根据文件的相对路径保持文件层级
    files.forEach((file) => {
      // 获取相对路径
      const relativePath = file.webkitRelativePath || file.name

      let newPath
      const newRootFolderName = options?.newRootFolderName || ''
      const pathParts = relativePath.split('/') // 分割文件路径
      if (pathParts.length > 1 && newRootFolderName) {
        pathParts[0] = newRootFolderName
        newPath = pathParts.join('/')
      } else {
        newPath = relativePath
      }

      // 将文件添加到 ZIP 中
      zip.file(newPath, file)
    })

    // 生成压缩文件，并返回 Blob 对象
    const zipBlob = await zip.generateAsync(
      { type: 'blob' },
      (metadata) => {
        // 调用进度回调函数
        if (onProgress) {
          onProgress(metadata.percent)
        }

        // 检查是否取消
        if (isCancelled) {
          throw new Error('Compression cancelled by user')
        }
      }
    )

    return zipBlob
  } catch (error) {
    throw new Error('Compressing files failed')
  }
}

/**
 * 取消压缩操作
 */
export const cancelCompression = () => {
  isCancelled = true
}

export function createFileWithRelativePath(file: File, fileRename?: string, webkitRelativePath?: string): File {
  // 创建一个新的 File 对象
  const tempFile = new File([file], fileRename || file.name)

  // 定义 webkitRelativePath 属性
  Object.defineProperty(tempFile, 'webkitRelativePath', {
    value: webkitRelativePath || file.webkitRelativePath,
    writable: true, // 设置为可写
    enumerable: true, // 使其在循环中可见
    configurable: true // 允许删除或修改
  })
  tempFile.uid = file.uid || generateUniqueId()
  return tempFile
}
