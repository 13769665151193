<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(-546.000000, -2291.000000)" :fill="colors[0]">
        <g transform="translate(-1.000000, 1826.000000)">
          <g transform="translate(29.000000, 115.000000)">
            <g transform="translate(518.000000, 350.000000)">
              <path
                d="M3,8 L4,8 C4.55228475,8 5,8.44771525 5,9 L5,22 C5,22.5522847 4.55228475,23 4,23 L3,23 C1.8954305,23 1,22.1045695 1,21 L1,10 C1,8.8954305 1.8954305,8 3,8 Z"
                id="矩形"
              />
              <path
                d="M8,8 L21.3967767,8 C22.5013462,8 23.3967767,8.8954305 23.3967767,10 C23.3967767,10.1739812 23.374075,10.3472186 23.3292466,10.5153253 L20.3959133,21.5153253 C20.1624448,22.3908321 19.3695448,23 18.4634434,23 L8,23 C7.44771525,23 7,22.5522847 7,22 L7,9 C7,8.44771525 7.44771525,8 8,8 Z"
                id="矩形备份-5"
              />
              <path
                d="M16.2096249,0.795464958 L16.5,0.9 C17.814352,1.37316672 18.4962674,2.82223696 18.0231007,4.13658897 C18.0090392,4.17564859 17.9940183,4.21435611 17.9780515,4.25267635 L16,9 L16,9 L8,9 L12.6973156,1.95402661 C13.4624885,0.806267283 14.9117318,0.328223459 16.2096249,0.795464958 Z"
                id="矩形备份-6"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'YbSvgLike',
  props: {
    colors: {
      type: Array as PropType<string[]>,
      default: () => {
        return []
      }
    },
    width: {
      type: String,
      default: '24'
    },
    height: {
      type: String,
      default: '24'
    }
  }
})
</script>
