
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'MSvgClose',
  props: {
    color: {
      type: String as PropType<string>,
      default: '#FFFFFF'
    },
    width: {
      type: String,
      default: '48'
    },
    height: {
      type: String,
      default: '48'
    },
    strokeWidth: {
      type: String as PropType<string>,
      default: '2'
    }
  }
})
