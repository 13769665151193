import { getTransitionLanguage } from '@/locales'
import moment from 'moment-timezone'

const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss'
const DATE_FORMAT = 'YYYY-MM-DD'
const AR_DATE_TIME_FORMAT = 'DD/MM/YYYY hh:mm'

export function formatToDateTime(
  date: moment.Moment | string | number | undefined = undefined,
  format = DATE_TIME_FORMAT,
  arFormat = AR_DATE_TIME_FORMAT
): string {
  // 检查 arFormat 中是否包含小时、分钟、秒的格式
  const hasTimeFormat = /[hHms]/.test(arFormat)
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  console.log('userTimeZone', userTimeZone)
  if (getTransitionLanguage() === 'ar') {
    // 获取时间在24小时制下的小时数，并将其转换为数字
    const hourIn24Format = parseInt(moment.tz(date, userTimeZone).format('H'), 10)
    console.log('hourIn24Format', hourIn24Format)
    // 根据小时数判断是上午还是下午，并使用相应的标记
    const amPmMark = hourIn24Format < 12 ? 'ص' : 'م'
    return hasTimeFormat ? moment.tz(date, userTimeZone).format(`${arFormat}${amPmMark}`) : moment.tz(date, userTimeZone).format(`${arFormat}`)
  }
  return moment.tz(date, userTimeZone).format(format)
}

export function formatToDate(
  date: moment.Moment | string | number | undefined = undefined,
  format = DATE_FORMAT
): string {
  return moment(date).format(format)
}

export const parseTime = (
  time?: string | number | null,
  cFormat?: string
): string | null => {
  if (time === undefined || !time) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date: Date
  if (typeof time === 'object') {
    date = time as Date
  } else {
    if (typeof time === 'string') {
      if (/^[0-9]+$/.test(time)) {
        time = parseInt(time)
      } else {
        time = time.replace(new RegExp(/-/gm), '/')
      }
    }
    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj: { [key: string]: number } = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const timeStr = format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value]
    }
    return value.toString().padStart(2, '0')
  })
  return timeStr
}

export const dateUtil = moment
