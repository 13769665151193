<template>
  <m-header fixed class="header" :style="[background ? `background-color: ${background}` : '#FFC400']">
    <m-row type="flex" align="center" justify="space-between" ph30 pv24>
      <m-col span="12" class="header__left">
        <a href="/">
          <img v-if="headerLogo" class="header__left--logo" radius8 :src="require(`../../assets/img/${headerLogo}`)" alt="weelife"/>
          <img v-if="headerNameLogo" class="header__left--name" ml9 :src="require(`../../assets/img/${headerNameLogo}`)"
            alt="weelife" />
        </a>
      </m-col>
      <m-col span="8" class="header__right">
        <a v-if="isShowBtn" @click="onBtnClick">
          <m-button class="header__right--button" fr
            :style="[btnBackground ? `background-color: ${btnBackground}` : '#fff', btnTextColor ? `color: ${btnTextColor}` : 'color: #272727']">
            {{ buttonText }}
          </m-button>
        </a>
      </m-col>
    </m-row>
  </m-header>
</template>
<script lang="ts">
import { OpenAppEnum } from './types'
import { PropType } from 'vue'

interface IHeaderState {
  onBtnClick: () => void
}

export default {
  props: {
    background: {
      type: String as PropType<string>
    },
    btnBackground: {
      type: String as PropType<string>
    },
    btnTextColor: {
      type: String as PropType<string>
    },
    isShowBtn: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    headerLogo: {
      type: String as PropType<string>,
      default: ''
    },
    headerNameLogo: {
      type: String as PropType<string>,
      default: ''
    },
    buttonText: {
      type: String as PropType<string>,
      default: '下载App'
    },
    handleOpenApp: {
      type: Function as PropType<(position: OpenAppEnum) => void>,
      required: false
    }
  },
  setup(props: Readonly<{
    handleOpenApp?: (position: OpenAppEnum) => void
  }>): IHeaderState {
    const onBtnClick = () => {
      props.handleOpenApp && props.handleOpenApp(OpenAppEnum.PLAY)
    }
    return {
      onBtnClick
    }
  }
}
</script>

<style lang="scss" scoped>
@include b(header) {
  width: 100%;
  max-width: 100%;

  @include e(left) {
    @include m(logo) {
      box-sizing: border-box;
      width: 60px;
      height: 60px;
      border: 1px solid $border-color-base;
    }

    @include m(name) {
      display: inline-block;
      height: 70px;
      width: auto;
      color: #ffffff;
      vertical-align: middle;
      font-weight: bold;
    }
  }

  @include e(right) {
    @include m(button) {
      width: 160px;
      height: 64px;
      font-weight: bold;
      font-size: 28px;
      border-radius: 32px;
      -webkit-user-select: none;
    }
  }
}
</style>
