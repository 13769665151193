export default {
  2: 'Parametre hatalı',
  2000: 'Parametre hatalı',
  2001: 'Sahne bulunamadı',
  2002: 'Sahne yayından kaldırıldı',
  9001: 'Sunucu geçici olarak hizmet dışı, lütfen tekrar deneyin',
  10004: 'Kullanıcı girişi başarısız',
  10007: 'Kullanıcı adı veya şifre hatalı',
  10008: 'Kullanıcı bulunamadı',
  10010: 'E-posta gönderimi başarısız',
  10019: 'E-posta ile şifre değiştirme kaydı bulunamadı',
  10020: 'Şifre sıfırlama bağlantısı süresi dolmuş',
  10021: 'Şifre sıfırlama bağlantısı zaten kullanıldı',
  10022: 'Şifre sıfırlama başarısız',
  10025: 'Şifre boş bırakılamaz',
  10030: 'Başka bir cihazda giriş yapılmış',
  10037: "Token'in süresi doldu",
  10039: 'Davet kodu bilgisi bulunamadı',
  10040: 'Davet kodu bilgisi alınamadı',
  10043: 'Çıkış yapıldı',
  10046: 'Sipariş bulunamadı',
  10047: 'Sipariş oluşturulamadı',
  10048: 'Sipariş yapılandırması bulunamadı',
  10064: 'Kullanıcı engellendi',
  10091: "Ödenmemiş sipariş sayısı 20'yi geçti",
  70000: 'Parametre hatalı',
  70001: 'Yükleme bilgisi bulunamadı veya yayından kaldırıldı'
}
