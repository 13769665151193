<template>
  <div class="app-content__download">
    <a :href="OpenUrlEnum.IOS_APP_STORE" target="_blank"
      @click="onClickToPath('apple_store', OpenUrlEnum.IOS_APP_STORE)">
      <!-- <img class="icon-sacle transtion-delay_100 transtion-duration_200" src="@/assets/img/first/apple_store.png"
        width="145" :style="{ display: 'block', maxWidth: imgMaxWidth }" border="0" alt="apple"> -->
      <img class="left-button icon-sacle transtion-delay_200 transtion-duration_300"
        src="@/assets/img/first/apple_store.png" :style="{ display: 'block' }" border="0" alt="apple">
    </a>
    <a :href="OpenUrlEnum.GOOGLE_PLAY" target="_blank"
      @click="onClickToPath('google_play', OpenUrlEnum.GOOGLE_PLAY)">
      <!-- <img class="icon-sacle transtion-delay_100 transtion-duration_200" src="@/assets/img/first/google_play.png"
        width="145" :style="{ display: 'block', maxWidth: imgMaxWidth, marginLeft: '10px' }" border="0" alt="google"> -->
      <img class="right-button icon-sacle transtion-delay_200 transtion-duration_300"
        src="@/assets/img/first/google_play.png" :style="{ display: 'block', marginLeft: '10px' }" border="0"
        alt="google">
    </a>
  </div>
</template>

<script lang='ts'>
import { useWindowSizeFn } from '@/hooks/useWindowSizeFn'
import { OpenUrlEnum } from '@/types/url-enum'
import { defineComponent, reactive, toRefs } from 'vue'
import { event } from 'vue-gtag'

interface IMDownloadState {
  imgMaxWidth: string
}
export default defineComponent({
  name: 'MDownload',
  setup() {
    const state = reactive<IMDownloadState>({
      imgMaxWidth: '165px'
    })
    const onClickToPath = (type: string, toPath: string) => {
      // 上报google analytics统计 --- apple store || google play 跳转
      if (type === 'apple_store') {
        // 跳转去苹果商店
        event('web_home_jump_ios', {
          event_category: 'web_home',
          to_path: toPath,
          jump_source: type
        })
      } else if (type === 'google_play') {
        // 跳转去google Play
        event('web_home_jump_android', {
          event_category: 'web_home',
          to_path: toPath,
          jump_source: type
        })
      }
    }

    useWindowSizeFn(
      () => {
        state.imgMaxWidth = window.innerWidth < 725 ? '135px' : '165px'
      },
      200,
      { immediate: true }
    )

    return {
      OpenUrlEnum,
      ...toRefs(state),
      onClickToPath
    }
  }
})
</script>
<style lang='scss' scoped>
.app-content__download {
  width: 100%;
  display: flex;
  justify-content: center;
}

.left-button {
  width: 9vw;
}

.right-button {
  width: 9vw;
}
@media screen and (max-width: 850px) {
  .left-button {
    width: 19vw;
  }

  .right-button {
    width: 19vw;
  }
}
</style>
