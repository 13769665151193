<template>
  <van-popup v-model:show="isShowDialog" :teleport="teleport" @click-overlay="handleClose" :style="vanPopupStyle"
    class="customDialog">
    <slot name="header">
    </slot>

    <div class="customDialog__middle">
      <slot name="content">
        <span>{{ message }}</span>
      </slot>
    </div>

    <div class="customDialog__footer">
      <slot name="footer">
        <van-button class="customDialog__footer--button" round type="default" @click="handleClose">{{ closeText
          }}</van-button>
        <van-button class="customDialog__footer--button customDialog__button--confirm" type="primary"
          @click="handleConfirm">{{
    confirmText }}</van-button>
      </slot>
    </div>
  </van-popup>
</template>

<script lang="ts">
import { reactive, toRefs, PropType, watchEffect, computed, defineComponent } from 'vue'
import { Popup, Button } from 'vant'

export default defineComponent({
  name: 'CustomDialog',
  components: {
    [Popup.name as string]: Popup,
    [Button.name as string]: Button
  },
  props: {
    modelValue: {
      type: Boolean as PropType<boolean>,
      required: true
    },
    teleport: {
      type: String as PropType<string>,
      default: 'body'
    },
    style: {
      type: Object as PropType<{}>,
      default: () => ({
        width: '60%',
        minWidth: '320px',
        maxWidth: '320px',
        borderRadius: '20px'
      })
    },
    message: {
      type: String as PropType<string>,
      default: '默认内容'
    },
    closeText: {
      type: String as PropType<string>,
      default: '取消'
    },
    confirmText: {
      type: String as PropType<string>,
      default: '确定'
    },
    onClose: {
      type: Function as PropType<() => void>,
      required: false
    },
    onConfirm: {
      type: Function as PropType<() => void>,
      required: false
    }
  },
  emits: {
    'update:modelValue': (show: boolean) => show
  },
  setup(props, { emit }) {
    const state = reactive({
      vanPopupStyle: props.style
    })

    const isShowDialog = computed(() => {
      return props.modelValue
    })

    watchEffect(() => {
      state.vanPopupStyle = props.style
    })

    const handleConfirm = () => {
      if (props.onConfirm) {
        props.onConfirm()
      } else {
        emit('update:modelValue', false)
      }
    }

    const handleClose = () => {
      if (props.onClose) {
        props.onClose()
      } else {
        emit('update:modelValue', false)
      }
    }

    return {
      isShowDialog,
      ...toRefs(state),
      handleConfirm,
      handleClose
    }
  }
})
</script>

<style lang="scss" scoped>
.customDialog {
  padding: 10px;

  &__header {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 10px;
  }

  &__middle {
    text-align: center;
    padding: 90px 50px;
    font-weight: 500;
    font-size: 30px;
    color: $btnText;
  }

  &__footer {
    width: 100%;
    padding: 22px 0;
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    .customDialog__footer--button {
      width: 40%;
      height: 80px;
      border-radius: 50px;
    }

    .customDialog__button--confirm {
      background: $btnBg !important;
      border: var(--van-button-border-width) solid $btnBg !important;
      color: $btnText;
    }
  }
}
</style>
