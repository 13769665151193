export default {
  2: '参数有误',
  2000: '参数有误',
  2001: '场景不存在',
  2002: '场景已下架',
  9001: '服务器开小差, 请重试',
  10004: '用户登陆失败',
  10007: '账号或密码错误',
  10008: '用户不存在',
  10010: '邮箱内容发送失败',
  10019: '邮箱修改密码记录不存在',
  10020: '重置密码连接已经过期',
  10021: '密码连接已经被重置过',
  10022: '重置密码失败',
  10025: '密码为空',
  10030: '在其它设备登录',
  10037: 'token已过期',
  10039: '找不到该邀请码信息',
  10040: '获取邀请码信息失败',
  10043: '已经退出登录',
  10046: '订单不存在',
  10047: '创建订单失败',
  10048: '订单配置不存在',
  10064: '用户被封禁',
  10091: '未支付订单数已超20',
  70000: '参数有误',
  70001: '充值信息不存在或已下架'
}
