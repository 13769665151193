
import { defineComponent, reactive, toRefs, onMounted, ref, Ref, PropType, watch } from 'vue'
interface IBoxClampState {
  expande: Ref<boolean>
  isOver: Ref<boolean>
  content: Ref<string>
  expandeClick: () => void
  boxClampDescRef: Ref<HTMLElement | undefined>
}
export default defineComponent({
  props: {
    value: {
      type: String as PropType<string>,
      required: true
    }
  },
  name: 'BoxClamp',
  setup(
    props: Readonly<{
      value: string
    }>
  ): IBoxClampState {
    const boxClampDescRef = ref<HTMLElement>()
    const state = reactive({
      expande: false,
      isOver: false,
      content: ''
    })

    watch(() => props.value, (val) => {
      state.content = val
    }, { immediate: true })

    const expandeClick = () => {
      state.expande = !state.expande
    }

    onMounted(() => {
      if (boxClampDescRef.value) {
        state.isOver =
          boxClampDescRef.value.offsetHeight < boxClampDescRef.value.scrollHeight
      }
    })

    return {
      boxClampDescRef,
      ...toRefs(state),
      expandeClick
    }
  }
})
