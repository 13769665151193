<template>
  <div class="wrap_footer" :class="{ 'nav-bar_rtl': language === 'ar' }" :style="{ backgroundColor: backgroundColor, color: textColor }">
    <div class="pc-content" v-if="!isShowMobile">
      <div class="p-links">
        <div class="item">
          <a href="/priv" :style="{ backgroundColor: backgroundColor, color: textColor }">
            <span class="link link_first">{{ $t('footer.priv') }}</span>
          </a>
          <a href="/userve" :style="{ backgroundColor: backgroundColor, color: textColor }">
            <span class="link link_first">{{ $t('footer.userve') }}</span>
          </a>
        </div>
        <div class="item">
          <span>{{ $t('footer.contact') }}:</span>
          <span>user_service@newlang.cn</span>
        </div>
        <div class="item">
          <span>{{ $t('footer.email') }}:</span>
          <span>cuizhiwei@newlang.cn</span>
        </div>
        <div class="item" v-if="isCompany">
          <a href="https://beian.miit.gov.cn/" target="_blank"
            :style="{ backgroundColor: backgroundColor, color: textColor }">
            <span>粤ICP备2021062879号-1</span>
          </a>
          <span>广州新言信息科技有限公司</span>
        </div>
      </div>
    </div>
    <div class="mobile-content" v-if="isShowMobile">
      <div class="m-links">
        <div class="item">
          <a href="/priv" :style="{ backgroundColor: backgroundColor, color: textColor }">
            <span class="link link_first">{{ $t('footer.priv') }}</span>
          </a>
          <span>{{ $t('footer.contact') }}:</span>
          <span>user_service@newlang.cn</span>
          <a v-if="isCompany" href="https://beian.miit.gov.cn/" target="_blank"
            :style="{ backgroundColor: backgroundColor, color: textColor }">
            <span>粤ICP备2021062879号-1</span>
          </a>
        </div>
        <div class="item">
          <a href="/userve" :style="{ backgroundColor: backgroundColor, color: textColor }">
            <span class="link link_first">{{ $t('footer.userve') }}</span>
          </a>
          <span>{{ $t('footer.email') }}:</span>
          <span>cuizhiwei@newlang.cn</span>
          <span v-if="isCompany">广州新言信息科技有限公司</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang='ts'>
import { useWindowSizeFn } from '@/hooks/useWindowSizeFn'
import { computed, defineComponent, PropType, ref } from 'vue'
import { getTransitionLanguage } from '@/locales'

export default defineComponent({
  name: 'MFooter',
  props: {
    backgroundColor: {
      type: String as PropType<string>,
      default: '#000000'
    },
    textColor: {
      type: String as PropType<string>,
      default: '#ffffff'
    }
  },
  setup() {
    const contentWeight = ref(window.innerWidth)
    const isShowMobile = ref(false)

    useWindowSizeFn(
      () => {
        contentWeight.value = window.innerWidth
        if (contentWeight.value <= 825) {
          isShowMobile.value = true
          return
        }
        isShowMobile.value = false
      },
      200,
      { immediate: true }
    )

    const language = computed(() => {
      return getTransitionLanguage()
    })

    const isCompany = computed(() => {
      return process.env.VUE_APP_USE === 'company'
    })

    return {
      isShowMobile,
      contentWeight,
      language,
      isCompany
    }
  }
})
</script>
<style lang='scss' scoped>
.nav-bar_rtl {
  direction: rtl;
}

.wrap_footer {
  width: 100%;
  padding-top: 2px;
  padding-bottom: 30px;
}

.pc-content {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
}

.p-links {
  width: 100%;
  height: 120px;
  display: flex;
  justify-content: space-evenly;
  padding-top: 6px;
}

.item {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  font-size: 30px;
  font-weight: 500;
}

.item .link {
  display: block;
  font-weight: 500;
  margin: 5px 0;
  text-decoration: none;
  font-size: 30px;
}

.mobile-content {
  height: 100%;
  width: 100%;
}

.m-links {
  display: flex;
  justify-content: space-evenly;
  height: 175px;

  .item {
    font-size: 24px;
  }

  .item .link {
    font-size: 24px;
  }
}

@media screen and (max-width: 375px) {
  .wrap_footer {
    padding-top: 0px;
    padding-bottom: 0px;
  }
}
</style>
