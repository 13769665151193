
import { reactive, toRefs, PropType, watchEffect, computed, defineComponent } from 'vue'
import { Popup, Button } from 'vant'

export default defineComponent({
  name: 'CustomDialog',
  components: {
    [Popup.name as string]: Popup,
    [Button.name as string]: Button
  },
  props: {
    modelValue: {
      type: Boolean as PropType<boolean>,
      required: true
    },
    teleport: {
      type: String as PropType<string>,
      default: 'body'
    },
    style: {
      type: Object as PropType<{}>,
      default: () => ({
        width: '60%',
        minWidth: '320px',
        maxWidth: '320px',
        borderRadius: '20px'
      })
    },
    message: {
      type: String as PropType<string>,
      default: '默认内容'
    },
    closeText: {
      type: String as PropType<string>,
      default: '取消'
    },
    confirmText: {
      type: String as PropType<string>,
      default: '确定'
    },
    onClose: {
      type: Function as PropType<() => void>,
      required: false
    },
    onConfirm: {
      type: Function as PropType<() => void>,
      required: false
    }
  },
  emits: {
    'update:modelValue': (show: boolean) => show
  },
  setup(props, { emit }) {
    const state = reactive({
      vanPopupStyle: props.style
    })

    const isShowDialog = computed(() => {
      return props.modelValue
    })

    watchEffect(() => {
      state.vanPopupStyle = props.style
    })

    const handleConfirm = () => {
      if (props.onConfirm) {
        props.onConfirm()
      } else {
        emit('update:modelValue', false)
      }
    }

    const handleClose = () => {
      if (props.onClose) {
        props.onClose()
      } else {
        emit('update:modelValue', false)
      }
    }

    return {
      isShowDialog,
      ...toRefs(state),
      handleConfirm,
      handleClose
    }
  }
})
