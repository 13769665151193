<template>
  <div class="upload">
    <div class="upload-content">
      <van-uploader v-bind="$attrs" v-model="uploaderList" :before-read="handleBeforeRead"
        :disabled="uploadStatus === 'loading'" :accept="accept">
        <van-button :disabled="uploadStatus === 'loading'" :style="btnStyle" v-bind="$attrs">{{ btnText }}</van-button>
      </van-uploader>
      <div class="upload-tip">
        <span class="upload-tip__text">
          <span v-if="!isShowUrl && uploadFile">{{ uploadFile.name }}</span>
          <a class="upload-url" v-if="isShowUrl && fileUrl" :href="fileUrl" target="_blank">
            {{ decodeURI(fileUrl) }}</a>
        </span>
        <span v-if="uploadFile">约{{ convertBytesToMB(uploadFile.size) + 'MB' }}</span>
      </div>
    </div>
    <van-progress class="upload-progress" color="#2F2F2F" v-if="isAutoUpload && uploadStatus === 'loading'"
      :percentage="percentage" stroke-width="20" text-inside="false" :background="false" />
  </div>
</template>

<script lang='ts'>
import { defineComponent, ref, watchEffect } from 'vue'
import { Uploader, Button, Toast, Progress, UploaderFileListItem } from 'vant'
import { splitFileNameV2 } from '@/utils'
import { useUpload } from '@/hooks/oss/ossUpload'
import { uploadButtonEmits, uploadButtonProps } from './data'
import { convertBytesToMB } from '@/utils/file'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'uploadButton',
  components: {
    [Button.name as string]: Button,
    [Uploader.name as string]: Uploader,
    [Progress.name as string]: Progress
  },
  emits: uploadButtonEmits,
  props: uploadButtonProps,
  setup(props, { emit }) {
    const { t } = useI18n()
    const fileUrl = ref('')
    const btnText = ref('')
    const uploadFile = ref<File>()
    const uploaderList = ref<UploaderFileListItem[]>()
    const percentage = ref<number>(0)
    function updateProgress(progress: number) {
      percentage.value = progress
    }

    watchEffect(() => {
      fileUrl.value = props.modelValue ?? ''
      btnText.value = props.btnText ?? '上传'
    })

    // 上传之前
    const handleBeforeRead = (file: File) => {
      const fileSize = (file.size as number) / 1024 / 1024
      /** 限制上传的文件大小 */
      if (props.limit?.maxSize && fileSize > props.limit.maxSize) {
        Toast.fail(props.limit?.sizeTip || `文件大小不能超过${props.limit.maxSize}MB哦~`)
        return
      }
      if (props.limit?.minSize && fileSize < props.limit.minSize) {
        Toast.fail(props.limit?.sizeTip || `文件大小不能小于${props.limit.minSize}MB哦~`)
        return
      }

      /** 文件名正则匹配 */
      if (props.limit?.pattern && props.limit?.pattern.test(file.name)) {
        Toast.fail(props.limit?.patternTip || '文件名不符合～')
        return
      }

      /** 文件名后缀校验 */
      if (props.accept && props.accept !== '*') {
        const acceptedTypes = props.accept.split(',').map((type) => type.trim())
        const { suffix } = splitFileNameV2(file.name as string)
        const isAcceptedType = acceptedTypes.includes(`.${suffix}`)
        if (!isAcceptedType) {
          Toast.fail(props.limit?.acceptTip || '文件格式不符合～')
          return
        }
      }

      if (!file) {
        Toast.fail(t('upload.failed'))
        return
      }

      // 如果唯一标识变量有值则拼接在文件名后
      let renameFile: File = {} as File
      if (props.ossFolderPath && props.single) {
        const { prefix, suffix } = splitFileNameV2(file.name as string)
        renameFile = new File([file], `${prefix}-${props.single}.${suffix}`)
        renameFile.uid = file.uid
      }
      upload(!props.single || !renameFile ? file : renameFile)
    }
    const { aliMultipartUpload, uploadStatus, progressNum } = useUpload()

    const upload = async (file: File) => {
      if (uploadStatus.value === 'loading') {
        Toast.fail(t('upload.repeat'))
        return
      }
      uploadFile.value = file
      if (!props.isAutoUpload) {
        emit('onChange', file)
        emit('update:modelValue', file.name)
        uploaderList.value = [{ url: file.name }]
        return
      }
      aliMultipartUpload(props.fileType, file, { destination: props.ossFolderPath }).then(res => {
        if (res) {
          emit('onSuccess', res)
          const fileUrl = res.url
          handleSuccess(fileUrl)
        }
      }).catch(err => {
        emit('onError', false)
        Toast.fail(t('upload.failed'))
        console.error(err)
      })
    }

    const handleSuccess = (url: string) => {
      fileUrl.value = url
      emit('update:modelValue', fileUrl.value)
      uploaderList.value = [{ url: fileUrl.value }]
      if (uploadFile.value?.size) {
        const size = uploadFile.value?.size
        // 触发自定义事件，将文件大小传递给父组件
        if (size) {
          emit('fileSizeChange', size)
        }
      }
    }

    // 开始上传
    return {
      uploaderList,
      uploadFile,
      fileUrl,
      progressNum,
      uploadStatus,
      convertBytesToMB,
      handleBeforeRead,
      percentage,
      updateProgress
    }
  }
})
</script>
<style lang='scss' scoped>
.upload {
  cursor: pointer;
}

.upload-content {
  display: flex;
  align-items: center;
  flex-flow: wrap;

  :deep(.van-uploader__preview) {
    display: none;
  }
}

.upload-tip {
  margin-left: 20px;
  display: flex;
  align-items: center;
  flex-flow: wrap;
  max-width: 840px;

  .upload-tip__text {
    display: block;
    width: 700px;
    @include box-clamp(2);
  }
}

.upload-progress {
  margin-top: 20px;
  margin-bottom: 10px;
}
</style>
