<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>ic_tap_icon_4</title>
    <g id="落地页" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(-524.000000, -798.000000)" fill="#F8CB62" id="编组-5">
        <g transform="translate(0.000000, 790.000000)">
          <g id="编组-4备份-4" transform="translate(513.000000, 0.000000)">
            <g id="ic_tap_icon_4" transform="translate(11.000000, 8.000000)">
              <path
                d="M3.48564293,8.82535718 L10.4856429,12.7142461 C10.8031081,12.8906156 11,13.2252362 11,13.5884033 L11,21.3004855 C11,21.8527703 10.5522847,22.3004855 10,22.3004855 C9.83005575,22.3004855 9.66291507,22.257175 9.51435707,22.1746428 L2.51435707,18.2857539 C2.19689188,18.1093844 2,17.7747638 2,17.4115967 L2,9.69951446 C2,9.14722971 2.44771525,8.69951446 3,8.69951446 C3.16994425,8.69951446 3.33708493,8.74282496 3.48564293,8.82535718 Z"
                id="矩形"
              />
              <path
                d="M14.4856429,8.82535718 L21.4856429,12.7142461 C21.8031081,12.8906156 22,13.2252362 22,13.5884033 L22,21.3004855 C22,21.8527703 21.5522847,22.3004855 21,22.3004855 C20.8300558,22.3004855 20.6629151,22.257175 20.5143571,22.1746428 L13.5143571,18.2857539 C13.1968919,18.1093844 13,17.7747638 13,17.4115967 L13,9.69951446 C13,9.14722971 13.4477153,8.69951446 14,8.69951446 C14.1699442,8.69951446 14.3370849,8.74282496 14.4856429,8.82535718 Z"
                id="矩形备份-42"
                transform="translate(17.500000, 15.500000) scale(-1, 1) translate(-17.500000, -15.500000) "
              />
              <path
                d="M12.4856429,1.26980163 L19.4265169,5.12584272 C19.9093006,5.39405591 20.0832444,6.0028592 19.8150312,6.48564293 C19.7243423,6.648883 19.589757,6.78346835 19.4265169,6.87415728 L12.4856429,10.7301984 C12.1836204,10.8979887 11.8163796,10.8979887 11.5143571,10.7301984 L4.5734831,6.87415728 C4.09069936,6.60594409 3.91675557,5.9971408 4.18496875,5.51435707 C4.27565768,5.351117 4.41024303,5.21653165 4.5734831,5.12584272 L11.5143571,1.26980163 C11.8163796,1.10201133 12.1836204,1.10201133 12.4856429,1.26980163 Z"
                id="矩形备份-43"
                transform="translate(12.000000, 6.000000) scale(-1, 1) translate(-12.000000, -6.000000) "
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'YbSvgOther',
  props: {
    colors: {
      type: Array as PropType<string[]>,
      default: () => {
        return []
      }
    },
    width: {
      type: String,
      default: '24'
    },
    height: {
      type: String,
      default: '24'
    }
  }
})
</script>
