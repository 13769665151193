
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'XySvgBack',
  props: {
    bgColor: {
      type: String as PropType<string>,
      default: '#5398FF'
    },
    color: {
      type: String as PropType<string>,
      default: '#FFFFFF'
    },
    direction: {
      type: String as PropType<'left' | 'right'>,
      default: 'left'
    },
    width: {
      type: String,
      default: '20px'
    },
    height: {
      type: String,
      default: '20px'
    }
  }
})
