<template>
  <svg
    :width="width"
    :height="width"
    viewBox="0 0 48 48"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>xxhdpi/ic_home_icon_coupons</title>
    <defs>
      <linearGradient
        x1="50%"
        y1="79.5857988%"
        x2="70.4828369%"
        y2="37.5446788%"
        id="linearGradient-1"
      >
        <stop stop-color="#FFA66F" offset="0%" />
        <stop stop-color="#FFC76F" stop-opacity="0.721563593" offset="100%" />
      </linearGradient>
      <linearGradient
        x1="47.6466448%"
        y1="29.4509397%"
        x2="50%"
        y2="63.8566954%"
        id="linearGradient-2"
      >
        <stop stop-color="#FFEB6B" offset="0%" />
        <stop stop-color="#FFC400" offset="100%" />
      </linearGradient>
      <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-3">
        <stop stop-color="#FFB400" offset="0%" />
        <stop stop-color="#FFA300" offset="100%" />
      </linearGradient>
      <filter id="filter-4">
        <feColorMatrix
          in="SourceGraphic"
          type="matrix"
          values="0 0 0 0 0.152941 0 0 0 0 0.152941 0 0 0 0 0.152941 0 0 0 1.000000 0"
        />
      </filter>
    </defs>
    <g id="落地页" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(-30.000000, -970.000000)" id="编组-8">
        <g transform="translate(0.000000, 943.000000)">
          <g id="ic_home_icon_coupons" transform="translate(30.000000, 27.000000)">
            <g id="编组-10" transform="translate(5.000000, 4.000000)">
              <path
                d="M5,6 L28.3492086,0.811286972 C29.9666083,0.451864821 31.569139,1.4716571 31.9285612,3.08905678 C31.9760444,3.30273147 32,3.52096111 32,3.73984815 L32,7 C32,9.209139 30.209139,11 28,11 L9,11 C6.790861,11 5,9.209139 5,7 L5,6 L5,6 Z"
                id="矩形备份-18"
                fill="#FF8400"
              />
              <rect
                id="矩形备份-12"
                fill="url(#linearGradient-1)"
                x="2"
                y="11"
                width="39"
                height="30"
                rx="8"
              />
              <rect
                id="矩形"
                stroke="#272727"
                stroke-width="2"
                fill="url(#linearGradient-2)"
                stroke-linejoin="round"
                x="-1"
                y="7"
                width="38"
                height="30"
                rx="5"
              />
              <path
                d="M31,8 C33.7614237,8 36,10.2385763 36,13 L36,31 C36,33.7614237 33.7614237,36 31,36 L25,36 L24.9995684,32.135 L25.0147186,32.136039 C26.0690804,32.136039 26.9328837,31.3201612 27.0092329,30.2853013 L27.0147186,30.136039 C27.0147186,29.0314695 26.1192881,28.136039 25.0147186,28.136039 C25.0096642,28.136039 25.0046141,28.1360577 24.9995684,28.1360952 L24.9995684,24.135 L25.0147186,24.136039 C26.0690804,24.136039 26.9328837,23.3201612 27.0092329,22.2853013 L27.0147186,22.136039 C27.0147186,21.0314695 26.1192881,20.136039 25.0147186,20.136039 C25.0096642,20.136039 25.0046141,20.1360577 24.9995684,20.1360952 L24.9995684,16.135 L25.0147186,16.136039 C26.0690804,16.136039 26.9328837,15.3201612 27.0092329,14.2853013 L27.0147186,14.136039 C27.0147186,13.0314695 26.1192881,12.136039 25.0147186,12.136039 C25.0096642,12.136039 25.0046141,12.1360577 24.9995684,12.1360952 L25,8 L31,8 Z"
                id="形状结合"
                fill="url(#linearGradient-3)"
              />
              <g filter="url(#filter-4)" id="编组-24" stroke-linecap="round" stroke-linejoin="round">
                <g transform="translate(7.000000, 15.000000)">
                  <polyline
                    id="路径-10备份"
                    stroke="#272727"
                    stroke-width="2"
                    points="1 0 5.94694233 5 11 0"
                  />
                  <line
                    x1="0"
                    y1="5"
                    x2="12"
                    y2="5"
                    id="路径-10备份"
                    stroke="#272727"
                    stroke-width="2"
                  />
                  <line
                    x1="1"
                    y1="10"
                    x2="11"
                    y2="10"
                    id="路径-10备份-2"
                    stroke="#272727"
                    stroke-width="2"
                  />
                  <line
                    x1="6"
                    y1="5"
                    x2="6"
                    y2="15"
                    id="路径-10备份-2"
                    stroke="#272727"
                    stroke-width="2"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'YbSvgCoupon',
  props: {
    colors: {
      type: Array as PropType<string[]>,
      default: () => {
        return []
      }
    },
    width: {
      type: String,
      default: '24'
    },
    height: {
      type: String,
      default: '24'
    }
  }
})
</script>
