<template>
  <svg
    :width="width"
    :height="width"
    viewBox="0 0 14 23"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>ic_common_icon_next_orange@2x</title>
    <g id="落地页" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(-701.000000, -982.000000)" fill="#FFA100" id="编组-8">
        <g transform="translate(0.000000, 943.000000)">
          <g id="更多" transform="translate(493.000000, 34.000000)">
            <g
              id="ic_common_icon_next_orange"
              transform="translate(215.000000, 17.000000) rotate(-90.000000) translate(-215.000000, -17.000000) translate(203.000000, 5.000000)"
            >
              <path
                d="M14.2419871,12 L6.57724376,4.35552598 C5.80758541,3.58790299 5.80758541,2.34334023 6.57724376,1.57571724 C7.3469021,0.808094253 8.59476482,0.808094253 9.36442316,1.57571724 L18.4227562,10.6100956 C19.1924146,11.3777186 19.1924146,12.6222814 18.4227562,13.3899044 L9.36442316,22.4242828 C8.59476482,23.1919057 7.3469021,23.1919057 6.57724376,22.4242828 C5.80758541,21.6566598 5.80758541,20.412097 6.57724376,19.644474 L14.2419871,12 Z"
                id="Combined-Shape"
                transform="translate(12.500000, 12.000000) rotate(90.000000) translate(-12.500000, -12.000000) "
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'YbSvgArrow',
  props: {
    colors: {
      type: Array as PropType<string[]>,
      default: () => {
        return []
      }
    },
    width: {
      type: String,
      default: '24'
    },
    height: {
      type: String,
      default: '24'
    }
  }
})
</script>
