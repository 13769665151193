// 绑定事件
export const on = (
  el: Element | HTMLElement | Document | Window,
  event: string,
  // eslint-disable-next-line no-undef
  handler: EventListenerOrEventListenerObject,
  useCapture = false
): void => {
  if (el && event && handler) {
    el.addEventListener(event, handler, useCapture)
  }
}

// 解绑事件
export const off = (
  el: Element | HTMLElement | Document | Window,
  event: string,
  // eslint-disable-next-line no-undef
  handler: EventListenerOrEventListenerObject,
  useCapture = false
): void => {
  if (el && event && handler) {
    el.removeEventListener(event, handler, useCapture)
  }
}

/**
 * 设置指定元素的显示样式
 * @param elementById - 元素的ID，可以是 'task-loader'、'ranking-loader'、'publicLoading-loader'
 * @param display - 显示样式，默认为 'none'
 */
export const setAppLaunchLoading = (
  elementById: 'app-loader',
  display = 'none'
) => {
  // 通过ID获取指定的元素
  const element = document.getElementById(elementById)
  const bodyElement = document.body
  // 如果元素存在，设置其 display 样式
  if (element) {
    element.style.display = display
  }
  if (bodyElement) {
    bodyElement.style.overflow = 'auto'
  }
}
