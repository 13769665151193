export default {
  2: 'خطأ',
  2000: 'خطأ',
  2001: 'المشهد غير موجود',
  2002: 'تمت إزالة المشهد',
  9001: 'السيرفر خارج الخدمة، يرجى المحاولة مرة أخرى',
  10004: 'فشل تسجيل الدخول',
  10007: 'اسم المستخدم أو كلمة المرور غير صحيحة',
  10008: 'المستخدم غير موجود',
  10010: 'فشل إرسال محتوى البريد الإلكتروني',
  10019: 'سجل تغيير كلمة المرور للبريد الإلكتروني غير موجود',
  10020: 'إنتهت صلاحية رابط إعادة تعيين كلمة المرور',
  10021: 'تمت إعادة تعيين رابط كلمة المرور',
  10022: 'فشل إعادة تعيين كلمة المرور',
  10025: 'كلمة المرور فارغة',
  10030: 'تسجيل الدخول من الأجهزة الأخرى',
  10037: 'token إنتهت الصلاحية',
  10039: 'لا يمكن العثور على معلومات رمز الدعوة',
  10040: 'فشل في الحصول على معلومات رمز الدعوة',
  10043: 'تم تسجيل الخروج',
  10046: 'الطلب غير موجود',
  10047: 'فشل إنشاء الطلب',
  10048: 'الطلب غير موجود',
  10064: 'تم حظر المستخدم',
  10091: 'عدد الطلبات غير المدفوعة تجاوز 20 طلباً',
  70000: 'خطأ',
  70001: 'معلومات الشحن غير موجودة أو تمت إزالتها'
}
