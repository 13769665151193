
import { OpenAppEnum } from './types'
import { PropType } from 'vue'

interface IHeaderState {
  onBtnClick: () => void
}

export default {
  props: {
    background: {
      type: String as PropType<string>
    },
    btnBackground: {
      type: String as PropType<string>
    },
    btnTextColor: {
      type: String as PropType<string>
    },
    isShowBtn: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    headerLogo: {
      type: String as PropType<string>,
      default: ''
    },
    headerNameLogo: {
      type: String as PropType<string>,
      default: ''
    },
    buttonText: {
      type: String as PropType<string>,
      default: '下载App'
    },
    handleOpenApp: {
      type: Function as PropType<(position: OpenAppEnum) => void>,
      required: false
    }
  },
  setup(props: Readonly<{
    handleOpenApp?: (position: OpenAppEnum) => void
  }>): IHeaderState {
    const onBtnClick = () => {
      props.handleOpenApp && props.handleOpenApp(OpenAppEnum.PLAY)
    }
    return {
      onBtnClick
    }
  }
}
