<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 28 28"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(-273.000000, -891.000000)">
        <g transform="translate(85.000000, 417.000000)">
          <g transform="translate(188.000000, 474.000000)">
            <circle id="椭圆形" :fill="colors[0]" cx="14" cy="14" r="14" />
            <line
              x1="14"
              y1="6"
              x2="14"
              y2="21"
              id="直线-2"
              :stroke="colors[1]"
              stroke-width="4"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <line
              x1="7"
              y1="14"
              x2="14"
              y2="21"
              id="直线-2"
              :stroke="colors[1]"
              stroke-width="4"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <line
              x1="14"
              y1="14"
              x2="21"
              y2="21"
              id="直线-2"
              :stroke="colors[1]"
              stroke-width="4"
              stroke-linecap="round"
              stroke-linejoin="round"
              transform="translate(17.500000, 17.500000) scale(-1, 1) translate(-17.500000, -17.500000) "
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'YbSvgDownload',
  props: {
    colors: {
      type: Array as PropType<string[]>,
      default: () => {
        return []
      }
    },
    width: {
      type: String,
      default: '28'
    },
    height: {
      type: String,
      default: '28'
    }
  }
})
</script>
