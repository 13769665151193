import { isAndroid, isiOS } from './is'

// // 创建桥接函数
// function setupWebViewJavascriptBridge(callback: (bridge: any) => void) {
//   // Android 使用
//   if (isAndroid) {
//     if (window.WebViewJavascriptBridge) {
//       callback(window.WebViewJavascriptBridge)
//     } else {
//       document.addEventListener(
//         'WebViewJavascriptBridgeReady',
//         function () {
//           callback(window.WebViewJavascriptBridge)
//         },
//         false
//       )
//     }
//     console.log('tag', '安卓')
//     sessionStorage.phoneType = 'android'
//   }

//   // iOS 使用
//   if (isiOS) {
//     if (window.WebViewJavascriptBridge) {
//       return callback(window.WebViewJavascriptBridge)
//     }
//     if (window.WVJBCallbacks) {
//       return window.WVJBCallbacks.push(callback)
//     }
//     window.WVJBCallbacks = [callback]
//     const WVJBIframe = document.createElement('iframe')
//     WVJBIframe.style.display = 'none'
//     WVJBIframe.src = 'wvjbscheme://__BRIDGE_LOADED__'
//     document.documentElement.appendChild(WVJBIframe)
//     setTimeout(function () {
//       document.documentElement.removeChild(WVJBIframe)
//     }, 0)
//     console.log('tag', 'ios')
//     sessionStorage.phoneType = 'ios'
//   }
// }

// // 注册回调函数，第一次连接时调用初始化函数（Android 需要初始化，iOS 不用）
// setupWebViewJavascriptBridge(function (bridge: any) {
//   if (isAndroid) {
//     // 初始化
//     bridge.init(function (message: any, responseCallback: any) {
//       const data = {
//         'Javascript Responds': 'Wee!'
//       }
//       responseCallback(data)
//     })
//   }
// })

// // 导出桥接对象
// const AppBridge = {
//   callHandler(name: string, data: any, callback: (responseData: any) => void) {
//     setupWebViewJavascriptBridge(function (bridge: any) {
//       bridge.callHandler(name, data, callback)
//     })
//   },
//   registerHandler(name: string, callback: (data: any, responseCallback: (responseData: any) => void) => void) {
//     setupWebViewJavascriptBridge(function (bridge: any) {
//       bridge.registerHandler(name, function (data: any, responseCallback: (responseData: any) => void) {
//         callback(data, responseCallback)
//       })
//     })
//   }
// }

export type AppBridgeMessageModuleType = 'data' | 'ui' | ''

export interface AppBridgeMessageType {
  method: string // 方法名 getUid、getLogInfo
  data: {
    type?: number //  1: 打开登录页  2: 跳转充值
    title?: string
    eventId?: string // 统计点需求定义的事件id（名称）
    'event_source'?: string // 来源 默认H5
    page?: string // 统计点所在的页面名称
    uploadFlag?: number // 0 不上报, 1 上报到SLS, 2 上报到SLS和第三方统计平台（友盟、趣丸等，不包括归因平台）（未来可能的3：上报到SLS、第三方统计平台、归因平台）
    params?: string
  }
  callBack: boolean // 需要客户端回调 不填默认不需要客户端回传
  module?: AppBridgeMessageModuleType // 模块操作类型: data(数据操作) ui(界面操作)
}

const AppBridge = (message: AppBridgeMessageType): Promise<string> => {
  console.log('message', message)
  return new Promise((resolve) => {
    console.log('isAndroid', isAndroid)
    console.log('isiOS', isiOS())
    if (isAndroid) {
      console.log('WeelifeAppInterface', (window as any).WeelifeAppInterface)
      if ((window as any).WeelifeAppInterface && (window as any).WeelifeAppInterface.jsCallNative) {
        resolve((window as any).WeelifeAppInterface.jsCallNative(JSON.stringify(message)) as string)
        console.log('Android', (window as any).WeelifeAppInterface.jsCallNative(JSON.stringify(message)) as string)
        return
      }
    } else if (isiOS()) {
      console.log('webkit', (window as any).webkit)
      // resolve(JSON.stringify({ getLogInfo: { os: 1, osver: '系统版本', netType: '' } }))
      if ((window as any).webkit &&
        (window as any).webkit.messageHandlers &&
        (window as any).webkit.messageHandlers.jsCallNative) {
        (window as any).webkit.messageHandlers.jsCallNative.postMessage(JSON.stringify(message));
        (window as any).nativeCallback = (value: string) => {
          resolve(value)
          console.log('iOS', value)
        }
        return
      }
    }
    console.log('其他系统', message)
    resolve('')
  })
}

export default AppBridge

export const setAndroidBridgeTitle = (title: string, isAppBridge = true) => {
  document.title = title
  if (isAppBridge) {
    AppBridge({
      method: 'setTitle',
      data: {
        title
      },
      callBack: true,
      module: 'data'
    })
  }
}
