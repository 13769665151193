interface MetaInfo {
  description?: string
  keywords?: string
  // 可以添加其他需要设置的 meta 标签的字段和类型
}

export function setMetaTags(metaInfo: MetaInfo): string {
  const head = document.getElementsByTagName('head')[0]

  // 设置 description
  if (metaInfo.description) {
    const descriptionMeta = document.querySelector('meta[name="description"]')
    if (descriptionMeta) {
      descriptionMeta.setAttribute('content', metaInfo.description)
    } else {
      const newDescriptionMeta = document.createElement('meta')
      newDescriptionMeta.name = 'description'
      newDescriptionMeta.content = metaInfo.description
      head.appendChild(newDescriptionMeta)
    }
  }

  // 设置 keywords（如果有的话）
  if (metaInfo.keywords) {
    const keywordsMeta = document.querySelector('meta[name="keywords"]')
    if (keywordsMeta) {
      keywordsMeta.setAttribute('content', metaInfo.keywords)
    } else {
      const newKeywordsMeta = document.createElement('meta')
      newKeywordsMeta.name = 'keywords'
      newKeywordsMeta.content = metaInfo.keywords
      head.appendChild(newKeywordsMeta)
    }
  }

  // 添加其他需要设置的 meta 标签

  // ... 可以根据需要设置其他 meta 标签

  // 返回成功标志或其他信息
  return ''
}
