import { OssMultipartUploadResult } from '@/api/types/aliOss'
import { isArray } from 'lodash'
import { PropType } from 'vue'

export interface FileItemType {
  name: string
  status: 'pending' | 'uploading' | 'success' | 'failed'
  progress: number
  file: File
  uid: number
}

export type fileDragLimitType = {
  max: number
  maxTip: string
}

interface webkitdirectoryLimitRuleType {
  validator: (files: File[], folders?: DataTransferItem[]) => boolean
  message: (files: File[], folders?: DataTransferItem[]) => string
}

export const uploadDraggerProps = {
  modelValue: {
    type: String as PropType<string>,
    default: ''
  },
  disabled: {
    type: Boolean as PropType<boolean>,
    default: false
  },
  drag: {
    type: Boolean as PropType<boolean>,
    default: true
  },
  multiple: {
    type: Boolean as PropType<boolean>,
    default: false
  },
  name: {
    type: String as PropType<string>,
    default: 'file'
  },
  accept: {
    type: String as PropType<string>,
    default: '*'
  },
  fileType: {
    type: Number as PropType<number>,
    default: 1
  },
  ossFolderPath: {
    type: String as PropType<string>,
    default: ''
  },
  fileRename: {
    type: String as PropType<string>,
    default: ''
  },
  webkitdirectory: {
    type: Boolean as PropType<boolean>,
    default: false
  },
  webkitdirectoryLimitRules: {
    type: Array as PropType<webkitdirectoryLimitRuleType[]>,
    default: [] as webkitdirectoryLimitRuleType[]
  },
  isCompress: {
    type: Boolean as PropType<boolean>,
    default: false
  },
  limit: {
    type: Object as PropType<fileDragLimitType>,
    default: () => {
      return {} as fileDragLimitType
    }
  }
}

// 定义进度状态类型
export interface UploadProgressStatus {
  state: 'start' | 'compressing' | 'uploading' | 'complete' | 'error'
  files?: File[] // 当前上传的文件
  errorMessage?: string | Error | unknown // 错误信息
}

export const uploadDraggerEmits = {
  file: (file: File[]) => isArray(file),
  onSuccess: (res: OssMultipartUploadResult) => res,
  onError: (error: any, file: File[]) => {
    return error !== undefined && Array.isArray(file)
  },
  'update:modelValue': (val: string) => val,
  change: (val: string) => val,
  fileSizeChange: (size: number) => !!size,
  uploadProgress: (progressStatus: UploadProgressStatus) => progressStatus // 修改进度状态名称
}
