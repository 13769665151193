<template>
  <svg :width="width" :height="height" viewBox="0 0 24 24" version="1.1">
    <title>icon_close</title>
    <g id="页面-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round"
      stroke-linejoin="round">
      <g id="素材下载" transform="translate(-1447.000000, -63.000000)" :stroke="color" :stroke-width="strokeWidth">
        <g id="icon_close" transform="translate(1448.000000, 64.000000)">
          <line x1="0" y1="0" x2="22" y2="22" id="直线" />
          <line x1="0" y1="0" x2="22" y2="22" id="直线备份"
            transform="translate(11.000000, 11.000000) scale(-1, 1) translate(-11.000000, -11.000000) " />
        </g>
      </g>
    </g>
  </svg>
</template>

<script lang='ts'>
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'MSvgClose',
  props: {
    color: {
      type: String as PropType<string>,
      default: '#FFFFFF'
    },
    width: {
      type: String,
      default: '48'
    },
    height: {
      type: String,
      default: '48'
    },
    strokeWidth: {
      type: String as PropType<string>,
      default: '2'
    }
  }
})
</script>
<style lang='scss' scoped>
</style>
