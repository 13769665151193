import { OssMultipartUploadResult } from '@/api/types/aliOss'
import { PropType } from 'vue'

export interface fileLimitType {
  maxSize?: number
  minSize?: number
  sizeTip?: string
  pattern?: RegExp // 正则
  patternTip?: string
  acceptTip?: string
}

export const uploadButtonProps = {
  modelValue: {
    type: [String, null] as PropType<string>,
    default: null
  },
  isShowUrl: {
    type: Boolean as PropType<boolean>,
    default: false
  },
  btnText: {
    type: String as PropType<string>,
    default: '上传文件'
  },
  btnStyle: {
    type: String as PropType<string>,
    default: ''
  },
  isAutoUpload: {
    type: Boolean as PropType<boolean>,
    default: true
  },
  fileType: {
    type: Number as PropType<number>,
    default: 1
  },
  ossFolderPath: {
    type: String as PropType<string>,
    default: ''
  },
  single: {
    type: [String, Number, null] as PropType<string | number | null>,
    default: null
  },
  limit: {
    type: Object as PropType<fileLimitType>,
    default: () => {
      return {} as fileLimitType
    }
  },
  accept: {
    type: String as PropType<string>,
    default: '*'
  }
}

export const uploadButtonEmits = {
  onChange: (file: File) => file,
  onSuccess: (res: OssMultipartUploadResult) => res,
  onError: (error: any) => !!error,
  'update:modelValue': (val: string) => val,
  input: (val: string) => val,
  fileSizeChange: (size: number | string) => size
}
