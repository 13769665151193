
import { defineComponent, ref } from 'vue'
import { FileItemType, uploadDraggerEmits, uploadDraggerProps } from './data'
import { Toast, Progress } from 'vant'
import { useUpload } from '@/hooks/oss/ossUpload'
import { splitFileNameV2 } from '@/utils'
import { OssMultipartUploadResult } from '@/api/types/aliOss'
import { useCustomFieldValue } from '@vant/use'
import { useI18n } from 'vue-i18n'
import { getAllFilesFromDirectory, extractFilesAndFolders, generateUniqueId, compressFiles, cancelCompression, createFileWithRelativePath } from './utils'
import { map } from 'lodash'

export default defineComponent({
  name: 'UploadDragger',
  components: {
    [Progress.name as string]: Progress
  },
  props: uploadDraggerProps,
  emits: uploadDraggerEmits,
  setup(props, { emit }) {
    const { t } = useI18n()
    const fileInput = ref<HTMLInputElement>()
    const dragover = ref(false)
    const fileList = ref<FileItemType[]>([])
    const { aliMultipartUpload, uploadStatus, aliCancelUpload } = useUpload()
    const isCompressing = ref(false)
    const compressProgress = ref(0)

    const onDrop = async (e: DragEvent) => {
      if (props.disabled) return
      dragover.value = false
      e.stopPropagation()

      if (!props.multiple || props.limit?.max === 1 || props.webkitdirectory) {
        if (uploadStatus.value === 'start' || uploadStatus.value === 'loading') {
          Toast.fail(t('upload.repeat'))
          return
        }
      }

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      if (e.dataTransfer!.items) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const items = Array.from(e.dataTransfer!.items)
        // 读取所有的文件和文件夹
        const { files, folders } = await extractFilesAndFolders(items)
        if (folders.length > 1) {
          Toast.fail(t('upload.fail_message_1'))
          return
        }

        // 处理文件夹内容
        const collectedFiles = folders.length !== 0 ? await Promise.all(folders.map(folder => getAllFilesFromDirectory(folder))) : []
        const allFiles = [...files, ...collectedFiles.flat()] // 扁平化文件数组

        if (allFiles.length > 0) {
          handleFiles(allFiles, folders) // 处理所有文件
        }
      }
    }

    const onDragover = () => !props.disabled && (dragover.value = true)

    const onDragleave = () => (dragover.value = false)

    const handleClick = () => {
      if (!props.multiple || props.limit?.max === 1 || props.webkitdirectory) {
        if (uploadStatus.value === 'start' || uploadStatus.value === 'loading') {
          Toast.fail(t('upload.repeat'))
          return
        }
      }
      if (!props.disabled) {
        if (fileInput.value) {
          fileInput.value.value = ''
          fileInput.value.click()
        }
      }
    }

    const handleFileChange = (event: Event) => {
      const files = (event.target as HTMLInputElement).files
      if (files) {
        const fileArray = map(files, (file) => file)
        handleFiles(fileArray)
      }
    }

    // 通用文件处理逻辑
    const handleFiles = async (files: File[], folders?: DataTransferItem[]) => {
      emit('uploadProgress', { state: 'start', files: files })
      let Files = files.filter(file => file.name !== '.DS_Store')

      if (Files.length === 0) {
        emit('uploadProgress', { state: 'error', files: files })
        return
      }
      if (props.limit.max && Files.length > props.limit.max) {
        Toast.fail(props.limit.maxTip || t('upload.max_file', { num: props.limit.max }))
        emit('uploadProgress', { state: 'error', files: files })
        return
      }

      Files = Files.map(file => {
        const tempFile = createFileWithRelativePath(file)
        return tempFile
      })
      console.log(Files)

      const processedFiles: FileItemType[] = Files.map((file) => {
        return {
          name: file.name,
          status: 'pending',
          progress: 0,
          file,
          uid: file.uid
        }
      })

      if (props.webkitdirectory) {
        // 处理额外的验证规则
        if (props.webkitdirectoryLimitRules && props.webkitdirectoryLimitRules.length > 0) {
          for (const rule of props.webkitdirectoryLimitRules) {
            if (!rule.validator(Files, folders)) {
              emit('uploadProgress', { state: 'error', files: files, errorMessage: rule.message(Files, folders) })
              Toast.fail(rule.message(Files, folders))
              return false
            }
          }
        }
        if (props.isCompress) {
          // 计算文件总大小（字节）
          const totalSize = Files.reduce((acc, file) => acc + file.size, 0)
          const MAX_SIZE = 1 * 1024 * 1024 * 1024 // 1GB in bytes
          if (totalSize > MAX_SIZE) {
            Toast.fail(t('upload.fail_message_2'))
            emit('uploadProgress', { state: 'error', files: files, errorMessage: t('upload.fail_message_2') })
            return
          }
          try {
            emit('uploadProgress', { state: 'compressing', files: files })
            isCompressing.value = true
            // 调用压缩函数，获取压缩后的文件 Blob
            const zipBlob = await compressFiles(files, (percent) => {
              compressProgress.value = Number(percent.toFixed(2))
            }, { newRootFolderName: props.fileRename })

            // 创建一个 File 对象用于上传
            const zipFile = new File([zipBlob], `${props.fileRename}.zip`, { type: 'application/zip' })
            zipFile.uid = generateUniqueId()
            // 更新 fileList，确保 ZIP 文件出现在列表中
            const zipFileItem: FileItemType = {
              name: zipFile.name,
              status: 'pending',
              progress: 0,
              file: zipFile,
              uid: zipFile.uid
            }
            fileList.value = [zipFileItem]
            isCompressing.value = false
            await uploadFile(zipFile)
          } catch (error) {
            console.error('处理文件上传时出错:', error)
          } finally {
            isCompressing.value = false
          }
        } else {
          fileList.value = [...fileList.value, ...processedFiles]
          uploadFiles(Files)
        }
      } else if (!props.multiple || props.limit?.max === 1) {
        fileList.value = processedFiles
        const file = Files[0]
        let fileToUpload = file
        if (props.fileRename) {
          const { suffix } = splitFileNameV2(file.name as string)
          fileToUpload = createFileWithRelativePath(file, `${props.fileRename}.${suffix}`)
        }
        uploadFile(fileToUpload)
      } else {
        fileList.value = [...fileList.value, ...processedFiles]
        uploadFiles(Files)
      }
    }

    const uploadFile = async (file: File) => {
      const fileIndex = fileList.value.findIndex(f => f.uid === file.uid)
      if (fileIndex === -1) return
      // 检查当前文件是否正在上传
      if (fileList.value[fileIndex].status === 'uploading') {
        Toast.fail(t('upload.repeat'))
        return
      }

      try {
        emit('uploadProgress', { state: 'uploading', files: [file] })
        fileList.value[fileIndex].status = 'uploading'

        const res = await aliMultipartUpload(props.fileType, file, {
          destination: props.ossFolderPath,
          onProgress: (progress) => (fileList.value[fileIndex].progress = progress)
        })
        if (res) {
          handleSuccess(res, file)
          fileList.value[fileIndex].status = 'success'
        }
      } catch (err) {
        fileList.value[fileIndex].status = 'failed'
        emit('onError', err, [file])
        emit('uploadProgress', { state: 'error', files: [file], errorMessage: err })
        if (err instanceof Error && !err.message.includes('cancelled')) {
          Toast.fail(t('upload.failed'))
        }
      }
    }

    // 批量上传
    const uploadFiles = async (files: File[]) => {
      const uploadPromises = files.map(file => uploadFile(file))
      try {
        await Promise.all(uploadPromises)
      } catch (err) {
        emit('uploadProgress', { state: 'error', files: files, errorMessage: err })
        console.error(err)
      }
    }

    useCustomFieldValue(() => props.modelValue)

    const handleSuccess = (succData: OssMultipartUploadResult, uploadFile: File) => {
      emit('onSuccess', succData)
      emit('update:modelValue', succData.url)
      emit('change', succData.url)
      emit('fileSizeChange', uploadFile.size || 0)
      emit('uploadProgress', { state: 'complete', files: [uploadFile] })
    }

    const retryUpload = (file: File) => uploadFile(file)

    const cancelUpload = () => {
      cancelCompression()
      fileList.value = fileList.value.map(item => {
        if (item.status !== 'success') {
          aliCancelUpload(item.uid) // 针对每个文件取消上传
          item.status = 'failed'
          item.progress = 0
        }
        return item
      })
      isCompressing.value = false
      compressProgress.value = 0
      emit('uploadProgress', { state: 'error', files: [] })
    }

    return {
      fileInput,
      dragover,
      fileList,
      onDragover,
      onDragleave,
      onDrop,
      handleClick,
      handleFileChange,
      retryUpload,
      isCompressing,
      compressProgress,
      cancelUpload
    }
  }
})
