
import { useWindowSizeFn } from '@/hooks/useWindowSizeFn'
import { computed, defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'MobileFooter',
  setup() {
    const contentWeight = ref(window.innerWidth)
    const isShowMobile = ref(false)

    useWindowSizeFn(
      () => {
        contentWeight.value = window.innerWidth
        if (contentWeight.value <= 825) {
          isShowMobile.value = true
          return
        }
        isShowMobile.value = false
      },
      200,
      { immediate: true }
    )

    const isCompany = computed(() => {
      return process.env.VUE_APP_USE === 'company'
    })

    return {
      isShowMobile,
      contentWeight,
      isCompany
    }
  }
})
