export default {
  route: {
    home: '首頁',
    music: '音樂上傳',
    about: '關於我們',
    upload: '上傳',
    login: '登陸',
    privacy: '隱私協議',
    userve: '用戶協議',
    recharge: '充值',
    udesk: '客服服務',
    quickGuide: 'weelife快速入門指南',
    roomRewards: 'Join room for rewards',
    updateContent: 'UPDATE CONTENT',
    friendSpace: '好友空間獎勵',
    userGroup: '官方用戶群',
    quickStartGuide: 'weelife快速入門指南',
    ktvParty: 'K歌派對',
    creatorPlatform: '創作者平臺',
    tutorial: '教程'
  },
  webMeta: {
    title: 'weelife',
    titleCompany: 'Newlang 新言',
    description: 'Weelife是一款專為Z世代年輕人打造、走線上上社交潮流前沿的虛擬現實語音社交產品，在次世代遊戲製作工藝的加持下，將高品質的遊戲化虛擬形象、虛擬場景與語音社交相結合，在移動端、VR端給社交用戶帶來前所未有的“超臨場”社交體驗。在Weelife世界中，可以通過精美的形象和服飾搭配來彰顯自己的個性人設；豐富、有趣的社交互動玩法，讓新朋友之間的社交破冰舒適而自然；多樣的社交場景，或溫馨浪漫或驚險刺激，與朋友在共同的經歷中建立羈絆；真實的語音、生動的肢體動作使互動過程更具情感和溫度；甚至可以建造自己的夢想場景或家園與朋友一起遊玩。Weelife的目標是，讓每一位用戶在這個充滿活力和溫情的烏托邦中，都可以收穫到自己聲音的迴響，遇到靈魂的羈絆。Weelife是一款尚為年輕的產品，需要不斷的成長才能更好的服務於廣大用戶。無論您是玩家還是創作者，無論您在世界何處，我們都期待聆聽您的意見和建議，與我們一起共建理想中的烏托邦。',
    descriptionCompany: '廣州新言資訊科技有限公司是一家互聯網公司，通過技術豐富用戶的線上社交體驗，將高品質的遊戲化虛擬形象、虛擬場景與語音社交相結合，為行動端與 VR 端的社交用戶帶來前所未有的「超臨場」社交體驗。',
    keywords: 'Weelife;Weelife app;Weelifeapp;weelife;weelife app;weelifeapp;WeelifeApp',
    keywordsCompany: '新言;新言官網;廣州新言;新言資訊;廣州新言資訊;新言資訊科技;廣州新言資訊科技;廣州新言資訊科技有限公司;newlang;newlang官網;newlang app;newlang App;Newlang'
  },
  errorPage404: {
    errMsg404: '網路請求錯誤,未找到該資源!',
    btnText: '打開App',
    MDialog: {
      headerText: '掃碼下載weelife App',
      btnText: '下載App'
    }
  },
  public: {
    downloading: '下載中...',
    wait: '請稍等...',
    openFail: '打開APP失敗,嘗試重新喚起'
  },
  footer: {
    priv: '隱私政策',
    userve: '用戶協議',
    contact: '聯繫我們',
    email: '合作郵箱'
  },
  homePage: {
    content: {
      second: {
        content_1: '個性裝扮',
        content_2: '閃亮你我',
        content_3: '將最美的自己展現給小夥伴們'
      },
      third: {
        content_1: '3D空間',
        content_2: '社交無界',
        content_3: '與小夥伴們一起在3d空間聊天和玩耍'
      },
      four: {
        content_1: '新奇朋友圈',
        content_2: '陪伴常在',
        content_3: '體驗新奇的3D朋友圈，感受不一樣的社交形態'
      },
      five: {
        content_1: 'K歌我最強',
        content_2: '盡顯歌王本色',
        content_3: '在炫酷的KTV空間一展歌喉，收穫喝彩！'
      },
      six: {
        content_1: '地圖配圖',
        content_2: '你來做主',
        content_3: '發揮想像力，創作奇思妙想的地圖，邀請好友一起遊覽~'
      }
    }
  },
  login: {
    content: {
      content_1: '歡迎來到Weelife',
      content_2: '這裏有捏臉換裝、聊天交友、酷炫場景，打造與眾不同的虛擬形象',
      content_3: '帳號密碼登錄',
      content_4: '登錄',
      content_5: '請輸入已註冊的郵箱',
      content_6: '請輸入密碼'
    },
    error: {
      content_1: '用戶未註冊',
      content_2: '輸入的密碼錯誤'
    },
    success: {
      tip_1: '登錄成功'
    }
  },
  goods: {
    table: {
      title: '我的商品', // 我的商品
      content_1: '資源ID',
      content_2: '部件型別',
      content_3: '上傳時間',
      content_4: '上傳時間',
      empty: '請使用PC端登入Weelife傳作者後臺上傳資源',
      loading: '加載中...'
    },
    data: {
      content_2: '成功'
    }
  },
  music: {
    content: {
      content_1: '已上傳歌曲',
      content_2: '首',
      content_3: '刷新',
      content_4: '上傳歌曲'
    },
    table: {
      content_1: '歌曲名稱',
      content_2: '歌手',
      content_3: '大小',
      content_4: '上傳時間',
      content_5: '上傳狀態',
      loading: '加載中...'
    },
    logout: {
      content_1: '退出登錄'
    },
    empty: {
      content_1: '暫無上傳歌曲'
    },
    upload: {
      content_1: '返回上一頁',
      content_2: '歌曲名稱',
      content_tip_2: '（必填，不超過20字）',
      content_3: '歌手',
      content_tip_3: '（必填，不超過20字）',
      content_4: '音樂資源',
      content_tip_4: '（必填，檔需大於512k且不超過20M，僅支持MP3格式）',
      content_5: '音樂語種',
      content_tip_5: '（必選）',
      content_6: '確定上傳',
      content_7: '我已經認真閱讀並同意以下《Weelife派對房音樂上傳分享服務規則》',
      content_8: '（請閱讀協議並勾選同意）',
      content_9: '上傳音樂成功！！！',
      content_10: '上傳失敗,請重新上傳！！！'
    },
    tips: {
      content_1: '請輸入歌曲名稱',
      content_2: '請輸入歌手名',
      content_3: '選擇檔',
      content_4: '覆蓋檔',
      content_5: '請選擇音樂語種',
      content_6: '確定上傳',
      content_7: '必須輸入歌曲名稱',
      content_8: '必須輸入歌手名',
      content_9: '選擇檔',
      content_10: '覆蓋檔',
      content_11: '未選擇檔',
      content_12: '檔案名存在非法字元字元(英文半角符號)',
      content_13: '必須選擇音樂類型',
      content_14: '檔格式不合符，僅支持MP3格式'
    }
  },
  about: {
    intro: {
      title: '關於weelife',
      content_1: 'Weelife是一款專為Z世代年輕人打造、走線上上社交潮流前沿的虛擬現實語音社交產品，在次世代遊戲製作工藝的加持下，將高品質的遊戲化虛擬形象、虛擬場景與語音社交相結合，在移動端、VR端給社交用戶帶來前所未有的“超臨場”社交體驗。',
      content_2: '在Weelife世界中，可以通過精美的形象和服飾搭配來彰顯自己的個性人設；豐富、有趣的社交互動玩法，讓新朋友之間的社交破冰舒適而自然；多樣的社交場景，或溫馨浪漫或驚險刺激，與朋友在共同的經歷中建立羈絆；真實的語音、生動的肢體動作使互動過程更具情感和溫度；甚至可以建造自己的夢想場景或家園與朋友一起遊玩。Weelife的目標是，讓每一位用戶在這個充滿活力和溫情的烏托邦中，都可以收穫到自己聲音的迴響，遇到靈魂的羈絆。',
      content_3: 'Weelife是一款尚為年輕的產品，需要不斷的成長才能更好的服務於廣大用戶。無論您是玩家還是創作者，無論您在世界何處，我們都期待聆聽您的意見和建議，與我們一起共建理想中的烏托邦。'
    },
    culture: {
      title: '企業文化',
      content_1: '開放透明',
      content_2: '簡單謙遜',
      content_3: '挑戰極致',
      content_4: '實幹創業'
    },
    welfare: {
      title: '福利待遇'
    },
    contact: {
      title: '聯繫我們',
      content_1: '地址：廣州市天河區中山大道西238號勤天大廈402',
      content_2: '電話：020-66837100',
      content_3: 'HR郵箱：'
    }
  },
  recharge: {
    header: '帳戶充值',
    title: {
      title_1: '請輸入您的帳號ID',
      title_2: '選擇儲值方式',
      title_3: '選擇儲值金額'
    },
    box_1: {
      uid: '帳號ID',
      placeholder: '請輸入您的帳號ID',
      btn: '驗證',
      switchText: '切換帳號'
    },
    bottom: {
      price: '應付金額',
      pay: '支付'
    },
    help: {
      header: '如何查看帳號ID？',
      title_1: '打開Weelife app，找到【我的】頁面',
      title_2: '在你的ID旁邊點擊【複製】按鈕即可',
      confirm_btn: '知道了!'
    },
    info: {
      header: '訂單詳情資訊',
      title: '請確認您的充值資訊',
      nickname: '昵稱',
      recharge: '充值金額',
      total_price: '總價格',
      cancel: '取消',
      confirm: '確認'
    },
    error: {
      uid_not: '此帳號ID不存在'
    },
    order: {
      submit_loading: '訂單提交中...',
      inquire_loading: '訂單查詢中...',
      success: '支付成功',
      success_info: '訂單充值成功！感謝您的使用和支援，請返回weelife檢視',
      timed_out: '訂單查詢超時',
      timed_out_info: '訂單查詢超時，點擊確認後繼續查詢',
      timed_out_info_two: '訂單查詢超時，請返回WeeLife-系統通知-聯絡客服查詢'
    }
  },
  creator: {
    mygoods: '我的商品',
    tutorial: '教程',
    menswear: '男裝',
    womenswear: '女裝',
    type_list: {
      TTF: '頭髮',
      TTS: '頭飾',
      TMZ: '帽子',
      TES: '耳飾',
      TMS: '面飾',
      TYJ: '眼鏡',
      XSY: '上衣',
      XWT: '外套',
      XJS: '頸飾',
      XBS: '背飾',
      SST: '手套',
      SSS: '手飾',
      PKZ: '褲子',
      PJSK: '緊身褲',
      PYS: '腰飾',
      JWZ: '襪子',
      JXZ: '鞋子',
      PWB: '尾巴',
      SJZ: '戒指',
      SBS: '臂飾',
      XLYQB: '罩袍',
      XLYQG: '連衣裙',
      PQZ: '裙子'
    }
  },
  creatorPlatform: {
    header: {
      logout: '退出登入',
      tip: {
        text_1: '1.服裝模型需按規範製作，具體請檢視【教程】',
        text_2: '檢視教程',
        text_3: '2.提交的模型需符合【平臺使用者協議】，否則無法透過稽覈',
        text_4: '檢視使用者協議'
      }
    },
    table: {
      uploaded: '已上傳',
      refresh: '重新整理',
      upload_model: '上傳模型',
      resourceId: '資源ID',
      type: '部件型別',
      size: '大小',
      uploadTime: '上傳時間',
      status: '上傳狀態',
      status_suc: '成功',
      status_err: '失敗',
      empty: '暫無上傳裝扮',
      loading: '加載中...'
    },
    upload_info: {
      back: '上一步',
      next: '下一步',
      confirm: '確認',
      steps: {
        text_1: '01 性別分類',
        text_2: '02 部件分類',
        text_3: '03 資源上傳'
      },
      from: {
        title: '你想上傳什麼呢?',
        course: '檢視教程',
        labels: {
          text_1: '選擇你想建立的型別',
          text_2: '選擇你想要建立的服裝型別'
        },
        dragger: {
          text_1: '請拖拽至此',
          text_2: '或上傳附件',
          text_3: '1.請確認上傳的裝扮分類是否正確，這將會影響商品在商城中的展示',
          text_4: '2.請確認模型符合規格，具體請檢視',
          text_5: '【服裝製作規範】'
        },
        upload_succ: '上傳成功',
        upload_error: '上傳失敗，請重新上傳',
        validator_1: '文件夾中需包含Models和Textures目錄，且不能爲空。',
        validator_2: 'Models文件夾下，請存放FBX後綴文件。',
        validator_3: 'Textures文件夾下，請存放TGA後綴文件。',
        go_back_stop: '資源尚未提交，是否放棄提交？'
      }
    }
  },
  language: {
    zh: '中文',
    'zh-Hant': '中文繁體',
    en: '英文',
    ar: '阿拉伯語',
    in: '印尼語',
    ms: '馬來語',
    tl: '菲律賓語',
    vi: '越南語',
    th: '泰語',
    tr: '土耳其語'
  },
  download: {
    btn: '立即下載'
  },
  popup: {
    cancel: '取消',
    confirm: '確認'
  },
  upload: {
    success: '已上傳',
    loading: '上傳中...',
    failed: '上傳失敗，請重新上傳',
    repeat: '上傳中！請勿重複上傳！',
    click_retry: '點選重傳',
    max_file: '檔案數量不能超過{num}個哦~',
    compressing: '压缩中...',
    cancel: '取消',
    fail_message_1: '只能拖入一个文件夹！',
    fail_message_2: '文件总大小不能超过 1GB'
  }
}
