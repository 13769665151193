import { setLang } from '@/utils/cookies'

export interface AppState {
  count: number
  swiperIndex: number | null
  hamburgerActive: boolean
  lang: string
}

const state: AppState = {
  count: 0,
  swiperIndex: 0,
  hamburgerActive: false,
  lang: ''
}

const mutations = {
  setSwiperIndex(state: AppState, index: number | null): void {
    state.swiperIndex = index
  },
  setHamburgerActive(state: AppState): void {
    state.hamburgerActive = !state.hamburgerActive
  },
  SET_LANG(state: AppState, lang: string): void {
    state.lang = lang
    setLang(lang)
  }
}

const actions = {

}

export const app = {
  namespaced: true,
  state,
  mutations,
  actions
}
