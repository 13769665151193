/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import Col from 'vant/lib/col'
import 'vant/lib/col/index.css'
import Row from 'vant/lib/row'
import 'vant/lib/row/index.css'
import { Layout, Header, Content, Footer } from './layout'
import MButton from './button'
import MCard from './card'
import MBoxClamp from './box-clamp'
import SvgStar from './svg-icon/svg-star.vue'
import SvgStarHalf from './svg-icon/svg-star-half.vue'
import SvgTag from './svg-icon/svg-tag.vue'
import SvgChat from './svg-icon/svg-chat.vue'
import SvgGame from './svg-icon/svg-game.vue'
import SvgMessage from './svg-icon/svg-message.vue'
import SvgLike from './svg-icon/svg-like.vue'
import SvgArrow from './svg-icon/svg-arrow.vue'
import SvgRocket from './svg-icon/svg-rocket.vue'
import SvgDownload from './svg-icon/svg-download.vue'
import SvgPlay from './svg-icon/svg-play.vue'
import SvgVip from './svg-icon/svg-vip.vue'
import SvgPay from './svg-icon/svg-pay.vue'
import SvgRebate from './svg-icon/svg-rebate.vue'
import SvgOther from './svg-icon/svg-other.vue'
import SvgWelfareParty from './svg-icon/svg-welfare-party.vue'
import SvgGift from './svg-icon/svg-gift.vue'
import SvgCoupon from './svg-icon/svg-coupon.vue'
import SvgClose from './svg-icon/svg-close.vue'
import SvgCopy from './svg-icon/svg-copy.vue'
import SvgUpload from './svg-icon/svg-upload.vue'
import SvgRefresh from './svg-icon/svg-refresh.vue'
import SvgBack from './svg-icon/svg-back.vue'
import { UploadButton, UploadDragger } from './upload/index'
import { App } from 'vue'

export default {
  install: (app: App) => {
    app.component('MCol', Col)
    app.component('MRow', Row)
    app.component('MLayout', Layout)
    app.component('MHeader', Header)
    app.component('MContent', Content)
    app.component('MFooter', Footer)
    app.component('MButton', MButton)
    app.component('MCard', MCard)
    app.component('MBoxClamp', MBoxClamp)
    app.component('MSvgStar', SvgStar)
    app.component('MSvgStarHalf', SvgStarHalf)
    app.component('MSvgChat', SvgChat)
    app.component('MSvgTag', SvgTag)
    app.component('MSvgMessage', SvgMessage)
    app.component('MSvgLike', SvgLike)
    app.component('MSvgArrow', SvgArrow)
    app.component('MSvgGame', SvgGame)
    app.component('MSvgRocket', SvgRocket)
    app.component('MSvgDownload', SvgDownload)
    app.component('MSvgPlay', SvgPlay)
    app.component('MSvgVip', SvgVip)
    app.component('MSvgPay', SvgPay)
    app.component('MSvgRebate', SvgRebate)
    app.component('MSvgOther', SvgOther)
    app.component('MSvgWelfareParty', SvgWelfareParty)
    app.component('MSvgGift', SvgGift)
    app.component('MSvgCoupon', SvgCoupon)
    app.component('MSvgClose', SvgClose)
    app.component('MSvgCopy', SvgCopy)
    app.component('MSvgUpload', SvgUpload)
    app.component('MSvgRefresh', SvgRefresh)
    app.component('MSvgBack', SvgBack)
    app.component('MUploadButton', UploadButton)
    app.component('MUploadDragger', UploadDragger)
  }
}
