import { createApp } from 'vue'
import App from './App.vue'
import MetaUI from './components'
import router from './router'
import './permission'

import 'normalize.css/normalize.css'
import '@/style/index.scss'
import 'vant/lib/index.css'
import utils, { openWindow } from '@/utils/index'
import i18n, { getTransitionLanguage } from '@/locales'
import VueGtag from 'vue-gtag'
import config from './api/config'
import '@vant/touch-emulator'
import { RouteLocationNormalized } from 'vue-router'
import { Lazyload, Popover } from 'vant'
import store from './store'
import { useTable } from './plugins/vxe-table'
import VConsole from 'vconsole'
import { getUserLocationIP } from './api/request/common'
import { startMonitor } from './monitor'

const { gaMeasurementId, meWebsiteURL } = config

/**
 * 获取用户当前IP地址
 * 例如: 中国|0|广东省|广州市|移动
 */
const getUserLocationCountry = async () => {
  let country = ''
  await getUserLocationIP().then(res => {
    if (res) {
      if (res.split('|').length > 1) {
        country = res.split('|')[0]
      } else {
        country = ''
      }
    }
  }).catch(err => {
    country = ''
    console.error(err)
  })
  return country
}

// 根据用户国家跳转网址
const toWebsiteURL = async () => {
  console.log('toWebsiteURL', process.env.NODE_ENV, process.env.VUE_APP_NAME)
  // 本地开发环境和中东地区项目无需跳转
  if (process.env.VUE_APP_ENV === 'development' || process.env.NODE_ENV === 'development' || process.env.VUE_APP_NAME === 'me' || process.env.VUE_APP_USE === 'company') {
    return
  }
  const country = await getUserLocationCountry()
  console.log('toWebsiteURL', country)
  const middleEastRegion = ['埃及', '阿联酋', '阿曼', '巴林', '卡塔尔',
    '科威特', '沙特阿拉伯', '叙利亚', '伊拉克', '约旦']
  const language = getTransitionLanguage()
  console.log('toWebsiteURL', language)
  // 如果用户属于中东地区,或者当前浏览器语言是阿拉伯语(暂时屏蔽)
  // 则跳转到对应中东网址
  if (middleEastRegion.includes(country)) {
    const { pathname, search } = new URL(window.location.href)
    const fullPath = pathname + search
    const toUrl = meWebsiteURL + fullPath
    console.log('toWebsiteURL', toUrl)
    openWindow(toUrl, { target: '_self' })
  }
}

async function bootstrap() {
  await toWebsiteURL()

  const app = createApp(App)
  app.use(MetaUI)
  app.use(utils)
  app.use(router)
  app.use(i18n)
  app.use(store)
  app.use(Lazyload)
  app.use(Popover)
  app.use(useTable)
  app.use(VueGtag, {
    pageTrackerTemplate(to: RouteLocationNormalized) {
      return {
        page_title: to.meta.title,
        page_path: to.path
      }
    },
    config: {
      id: gaMeasurementId
    }
  }, router)

  startMonitor({
    app,
    router
  })

  app.mount('#app')
}

bootstrap()

if (process.env.VUE_APP_ENV === 'development' || process.env.VUE_APP_ENV === 'stable') {
  const vConsole = new VConsole()
  console.log(vConsole) // 添加这行代码来使用 vConsole 对象
}
