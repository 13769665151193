import { App } from 'vue'
import { formatToDateTime } from './date'
import { errorCodeLocalesV2 } from './locales'
import { isAndroid, isExternal, isiOS } from './is'
import { scssVariables } from './scssVariables'

export function openWindow(
  url: string,
  opt?: { target?: TargetContext | string, noopener?: boolean, noreferrer?: boolean }
) {
  const { target = '__blank', noopener = true, noreferrer = true } = opt || {}
  const feature: string[] = []

  noopener && feature.push('noopener=yes')
  noreferrer && feature.push('noreferrer=yes')

  if (isAndroid) {
    const tempLink = document.createElement('a')
    tempLink.style.display = 'none'
    tempLink.href = url
    // tempLink.target = target
    document.body.appendChild(tempLink)
    tempLink.click()
    document.body.removeChild(tempLink)
  } else if (isiOS()) {
    window.location.href = url
  } else {
    window.open(url, target, feature.join(','))
  }
}

/**
 * 切割文件名版本-V2
 * 为兼容非资源文件命名
 * @param path
 * @returns
 */
export const splitFileNameV2 = (path: string) => {
  let fileArr: string | any[] = []
  let fileName = ''
  let suffix = ''
  // 判断是否为外链接地址，或者全路径地址
  // 是则提取文件名+后缀
  if (isExternal(path) || path.split('/').length > 1) {
    const tempArr = path.split('/')
    fileName = tempArr[tempArr.length - 1]
  } else {
    fileName = path
  }
  fileArr = fileName.split('.')
  if (fileArr.length >= 3) {
    const validSuffixArr = ['mp3', 'wav', 'ogg', 'aac', 'wma', 'flac', 'm4a', 'ac3', 'amr',
      'mp4', 'mov', 'avi', 'wmv', 'flv', 'mkv', 'mpg', 'mpeg',
      'lrc', 'unity3d', 'txt', 'json']
    if (validSuffixArr.includes(fileArr[fileArr.length - 2])) {
      suffix = fileArr[fileArr.length - 2] + '.' + fileArr[fileArr.length - 1]
    } else {
      suffix = fileArr[fileArr.length - 1]
    }
  } else {
    suffix = fileArr[fileArr.length - 1]
  }
  const prefix = fileName.split('.' + suffix)[0]
  console.log('suffix && prefix', suffix, prefix)
  return {
    prefix,
    suffix
  }
}

// 获取数组中的文本
export const findLabel = (arr: Array<{ value: number | string | null | boolean, label: string | number | null | boolean }>, value: boolean | number | string | null) => {
  if (value === null || value === '' || value === undefined) return
  return arr.find((obj) => obj.value === value)?.label
}

export default {
  install: (app: App) => {
    app.config.globalProperties.$errorCodeLocales = errorCodeLocalesV2
    app.config.globalProperties.$formatToDateTime = formatToDateTime
    app.config.globalProperties.$findLabel = findLabel
    app.config.globalProperties.$scssVariables = scssVariables
  }
}

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $errorCodeLocales: typeof errorCodeLocalesV2
    $formatToDateTime: typeof formatToDateTime
    $findLabel: typeof findLabel
    $scssVariables: typeof scssVariables
  }
}
