import { SelectTypeItem } from '@/types/common'

export interface IDropdownState {
  show: boolean
  value: string | number | boolean | null
  customFieldName: object
  isMobile: boolean | null | RegExpMatchArray
  defaultIndex: number
  isSlots: boolean
}

export const metaDropdownEmits = {
  onConfirm: (item: SelectTypeItem) => item,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onCancel: () => { },
  'update:modelValue': (val: string | number | boolean | null) => val,
  input: (val: string) => val
}
