import UaParser from 'ua-parser-js'

export function is(val: unknown, type: string) {
  return toString.call(val) === `[object ${type}]`
}

/** 判断是否是移动端 */
export const isMobile = () => {
  const flag = window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone|weelife|Weelife)/i)
  return flag
}

/** 判断是否为对象 */
export const isNullObject = (val: object) => {
  const data = { ...val }
  if (Object.keys(data).length === 0) {
    return false
  }
  return true
}

export function isString(val: unknown): val is string {
  return is(val, 'String')
}

/** 检验URL链接是否有效 */
export function getUrlState(URL: string) {
  const xmlHttp = new ActiveXObject('microsoft.xmlHttp')
  xmlHttp.Open('GET', URL, false)
  try {
    xmlHttp.Send()
  } catch (e) {
  } finally {
    const result = xmlHttp.responseText
    if (result) {
      if (xmlHttp.Status === 200) {
        // eslint-disable-next-line no-unsafe-finally
        return true
      } else {
        // eslint-disable-next-line no-unsafe-finally
        return false
      }
    } else {
      // eslint-disable-next-line no-unsafe-finally
      return false
    }
  }
}

/**
 * 判断path是否为外链
 * @param {string} path
 * @returns {Boolean}
 */
export const isExternal = (path: string) => /^(https?:|mailto:|tel:|blob:)/.test(path)

export function isWindow(val: any): val is Window {
  return typeof window !== 'undefined' && is(val, 'Window')
}

export function isObject(val: any): val is Record<any, any> {
  return val !== null && is(val, 'Object')
}

export function isDef<T = unknown>(val?: T): val is T {
  return typeof val !== 'undefined'
}

export function isUnDef<T = unknown>(val?: T): val is T {
  return !isDef(val)
}

export function isFunction(val: unknown): val is Function {
  return typeof val === 'function'
}

/**
 * 检测是否为合法正确的url
 * @param strUrl
 */
export function isCorrectUrl(strUrl: string) {
  const strRegex = '^((https|http)://)?' + '(([0-9a-z]+\\.)*' + '([0-9a-z][0-9a-z-]{0,20})?[0-9a-z]\\.' + '[a-z]{2,6})' + '(:[0-9]{1,4})?' + '((/?)|' + '(/[0-9a-z_!~*().;?:@&=+$,%#-]+)+/?)$'
  const re = new RegExp(strRegex, 'g')
  if (re.test(strUrl)) {
    return true
  }
  return false
}

/** 判断字符串是否为Json字符串 */
export function isJsonString(val: unknown): val is string {
  if (isString(val)) {
    try {
      const obj = JSON.parse(val)
      if (typeof obj === 'object' && obj) {
        return true
      } else {
        return false
      }
    } catch (e) {
      return false
    }
  }
  return false
}

const uaParser = new UaParser()
const ua = navigator.userAgent || ''
/** 判断是否Touch屏幕 */
const isTouchScreen = () => {
  return (
    'ontouchstart' in window ||
    (window.DocumentTouch && document instanceof window.DocumentTouch)
  )
}

// 判断设备类型
/** 判断是否为ipad */
export const isIpad = () => {
  const platform = window.navigator.platform
  const uaInfo = window.navigator.userAgent
  const macos = platform === 'MacIntel'
  const screenWidth = screen.width
  const screenHeight = screen.height
  if (uaInfo.match(/(iPad).*OS\s([\d_]+)/)) {
    return true
  }
  if (macos && isTouchScreen() && (
    (screenWidth === 1024 && screenHeight === 1366) || // Pro 12.9
    (screenWidth === 834 && screenHeight === 1194) || // Pro 11
    (screenWidth === 834 && screenHeight === 1112) || // Pro 10.5
    (screenWidth === 768 && screenHeight === 1024) // other
  )) {
    return uaInfo.match(/(Version)\/([\d.]+)/)
  }
}

export const isMac = () => {
  const platform = window.navigator.platform.toLowerCase()
  return platform.includes('mac')
}

export const isSafari = () => {
  const userAgent = window.navigator.userAgent.toLowerCase()
  return /safari/i.test(userAgent) &&
    !/(aol|edge|edge-ios|edgi|yandexbrowser|kakaotalk|samsung|silk|miui|beaker|edge-chromium|chrome|chromium-webview|phantomjs|crios|firefox|fxios|opera-mini|opera|pie|netfront|ie|bb10|android|facebook|instagram|curl|searchbot|brave|opt|aloha|qq)/i.test(userAgent)
}

// 是否支持ApplePay支付
export const hasApplePaySupport = typeof (window as any).ApplePaySession === 'function'

export const isAndroid = uaParser.getResult().os.name === 'Android' || /Android/i.test(ua)

export const isiOS = () => {
  if (uaParser.getResult().os.name === 'iOS' || isIpad() || /iphone|ipad|ipod|iOS/i.test(ua)) {
    return true
  } else if ((window as any).webkit) {
    return true
  }
  return false
}
