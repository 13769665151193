<template>
  <section id="m-layout" class="m-layout" :class="{ 'm-layout--horizontal': horizontal }">
    <slot></slot>
  </section>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'MLayout',
  props: {
    horizontal: {
      type: Boolean,
      default: false
    }
  }
})
</script>
<style lang="scss" scoped>
@include b(m-layout) {
  display: flex;
  flex-direction: column;

  @include m(horizontal) {
    flex-direction: row;
  }
}
</style>
