<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>ic_tap_icon_1</title>
    <g id="落地页" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g
        transform="translate(-42.000000, -798.000000)"
        fill="#FF7D61"
        fill-rule="nonzero"
        id="编组-5"
      >
        <g transform="translate(0.000000, 790.000000)">
          <g id="送VIP" transform="translate(30.000000, 0.000000)">
            <g id="ic_tap_icon_1" transform="translate(12.000000, 8.000000)">
              <path
                d="M13.0575345,3.88995407 C13.1344958,3.95522911 13.2054683,4.02749473 13.2695752,4.1058583 L16.4061261,7.93829876 L19.8659703,5.33679288 C20.4267032,4.91507124 21.1870563,4.89706262 21.7664403,5.2917811 C22.3458244,5.68649957 22.6195735,6.40901369 22.4506645,7.09767422 L19.1599021,20.5 L4.84026687,20.5 L1.54950452,7.098235 C1.38027022,6.40948288 1.65389267,5.6866912 2.23335774,5.2917918 C2.8128228,4.8968924 3.57338479,4.91489817 4.13419868,5.33679288 L7.59404287,7.93829876 L10.7305938,4.1058583 C11.0110691,3.76288016 11.4138954,3.54743557 11.8504118,3.50694316 C12.2869283,3.46645074 12.7213583,3.60422924 13.0580852,3.88995407 L13.0575345,3.88995407 Z M15.8553709,15.7147769 L8.14479809,15.7147769 L8.14479809,17.5093057 L15.8553709,17.5093057 L15.8553709,15.7147769 Z"
                id="形状"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'YbSvgVip',
  props: {
    colors: {
      type: Array as PropType<string[]>,
      default: () => {
        return []
      }
    },
    width: {
      type: String,
      default: '24'
    },
    height: {
      type: String,
      default: '24'
    }
  }
})
</script>
