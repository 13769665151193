<template>
  <header class="MHeader" :class="{ 'MHeader--fixed': fixed }">
    <slot></slot>
  </header>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'MHeader',
  props: {
    fixed: {
      type: Boolean,
      default: false
    }
  }
})
</script>
<style lang="scss" scoped>
@include b(MHeader) {
  @include m(fixed) {
    position: fixed;
    top: 0px;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 2000;
  }
}
</style>
