export default {
  2: 'Invalid credentials.',
  2000: 'Invalid credentials.',
  2001: 'Scenario does not exist.',
  2002: 'This scenario has been removed.',
  9001: 'Pelayan sepi, sila cuba lagi',
  10004: 'User login failure.',
  10007: 'The account or password is incorrect.',
  10008: 'User does not exist.',
  10010: 'Failed to send email content.',
  10019: 'Password change record for this mailbox does not exist.',
  10020: 'Password reset connection expired.',
  10021: 'Password connection reset.',
  10022: 'Failed to reset password.',
  10025: 'Password is empty',
  10030: 'Login on other devices',
  10037: 'Token has expired',
  10039: 'The invitation code information could not be found.',
  10040: 'Failed to get invitation code information.',
  10043: 'Logged out',
  10046: 'Order does not exist',
  10047: 'Order creation failure',
  10048: 'The order configuration does not exist',
  10064: 'The user is blocked.',
  10091: 'Unpaid orders have exceeded 20',
  70000: 'Invalid credentials.',
  70001: 'Recharge information does not exist or has been removed'
}
