import { AppState } from './modules/app'
import { UserState } from './modules/users'
import { GetterTree } from 'vuex'

interface RootState {
  app: AppState
  user: UserState
}

const getters: GetterTree<RootState, RootState> = {
  count: (state) => state.app.count,
  swiperIndex: (state) => state.app.swiperIndex,
  hamburgerActive: (state) => state.app.hamburgerActive,
  token: (state) => state.user.token,
  userInfo: (state) => state.user.userInfo
}

export default getters
