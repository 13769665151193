<template>
  <van-popup v-model:show="isShowDialog" teleport="body" @click-overlay="handleClose" :style="vanPopupStyle"
    class="mDialog">
    <m-nav-bar :title="headerText" :onClose="handleClose">
    </m-nav-bar>
    <div class="mDialog__middle">
      <qr-code :value="qrCodeUrl" :logo="require(`../../assets/img/${qrCodeLogo}`)"></qr-code>
    </div>
    <div class="mDialog__footer">
      <van-button class="mDialog__footer--button" type="primary" color="#7A6ED6" @click="handleClickBtn"
        @touchstart="handleClickBtn">{{ btnText }}
      </van-button>
    </div>
  </van-popup>
</template>

<script lang='ts'>
import { reactive, toRefs, Ref, PropType, watchEffect, ref, defineAsyncComponent } from 'vue'
import { Popup, Button } from 'vant'
import { useWindowSizeFn } from '@/hooks/useWindowSizeFn'

interface MDialogState {
  isShowDialog: Ref<boolean>
  vanPopupStyle: object
  handleClickBtn: () => void
  handleClose: () => void
}
export default {
  name: 'MDialog',
  components: {
    [Popup.name as string]: Popup,
    [Button.name as string]: Button,
    MNavBar: defineAsyncComponent(() => import('./nav-bar.vue')),
    QrCode: defineAsyncComponent(() => import('../../components/qr-code/src/qrcode.vue'))
  },
  props: {
    show: {
      type: Boolean as PropType<boolean>,
      required: true,
      default: false
    },
    style: {
      type: Object as PropType<{}>,
      default: () => {
        return {
          width: '40%',
          minWidth: '320px',
          maxWidth: '320px'
        }
      }
    },
    headerText: {
      type: String as PropType<string>,
      default: '手机扫码下载weelife App'
    },
    btnText: {
      type: String as PropType<string>,
      default: '下载App'
    },
    qrCodeUrl: {
      type: String as PropType<string>,
      default: window.location.href
    },
    qrCodeLogo: {
      type: String as PropType<string>,
      default: 'logo.png'
    },
    onClose: {
      type: Function as PropType<() => void>,
      required: false
    },
    onDownload: {
      type: Function as PropType<() => void>,
      required: false
    }
  },
  setup(
    props: Readonly<{
      show: boolean
      style: object
      onClose?: () => void
      onDownload?: () => void
    }>
  ): MDialogState {
    const state = reactive({
      isShowDialog: props.show,
      vanPopupStyle: props.style
    })

    watchEffect(() => {
      state.vanPopupStyle = props.style
    })

    const contentWeight = ref(window.innerWidth)

    useWindowSizeFn(
      () => {
        contentWeight.value = window.innerWidth
        if (contentWeight.value <= 320) {
          state.vanPopupStyle = {
            minWidth: '280px'
          } as object
        }
      },
      200,
      { immediate: true }
    )

    watchEffect(() => {
      state.isShowDialog = props.show
    })

    const handleClickBtn = () => {
      props.onDownload && props.onDownload()
    }

    const handleClose = () => {
      props.onClose && props.onClose()
    }

    return {
      ...toRefs(state),
      handleClickBtn,
      handleClose
    }
  }
}
</script>
<style lang='scss' scoped>
@include b(mDialog) {
  padding: 10px;

  @include e(middle) {
    text-align: center;
  }

  @include e(footer) {
    width: 100%;
    padding: 22px 0;
    display: flex;
    align-items: center;
    justify-content: center;

    @include m(button) {
      width: 70%;
      height: 70px;
      background: linear-gradient(180deg, #6D6DCE 0%, #857CE1 100%);
      border-radius: 40px;
    }
  }
}
</style>
