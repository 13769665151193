import { tryOnMounted, tryOnUnmounted, useDebounceFn } from '@vueuse/core'
import { Ref } from 'vue'

interface ScrollTopOptions {
  once?: boolean
  immediate?: boolean
  listenerOptions?: AddEventListenerOptions | boolean
  refEl?: Ref<Element | Window | null>
}

export function useScrollTopFn(fn: Fn<any>, wait = 0, options?: ScrollTopOptions) {
  let handler = (e: any) => {
    fn(e)
  }

  const handleSize = useDebounceFn(handler, wait)
  handler = handleSize

  const start = () => {
    if (options && options.immediate) {
      handler({} as any)
    }
    if (options?.refEl && options?.refEl.value) {
      options.refEl.value && options.refEl.value.addEventListener('scroll', handler, true)
    } else {
      window.addEventListener('scroll', handler, true)
    }
  }

  const stop = () => {
    if (options?.refEl && options?.refEl.value) {
      options.refEl.value && options.refEl.value.removeEventListener('scroll', handler)
    } else {
      window.removeEventListener('scroll', handler)
    }
  }

  tryOnMounted(() => {
    start()
  })

  tryOnUnmounted(() => {
    stop()
  })

  return [start, stop]
}
