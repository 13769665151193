/**
 * 字节(B) => 兆字节(MB)
 * @param bytes
 * @returns
 */
export function convertBytesToMB(bytes: number): string {
  if (!bytes) {
    return '0'
  }
  const megabytes = bytes / (1024 * 1024)
  return `${parseFloat(megabytes.toFixed(2))}`
}

export const bytesToSize = (bytes: number): string => {
  const isMacOS = (): boolean => {
    return /Mac/.test(navigator.platform)
  }

  const sizes = ['B', 'KB', 'MB', 'GB', 'TB']
  if (bytes === 0) return '0 B'
  const i = Math.floor(Math.log(bytes) / Math.log(1024))
  const size = bytes / Math.pow(isMacOS() ? 1000 : 1024, i)
  const formattedSize = i === 0 ? size : size.toFixed(2) // 保留两位小数
  return formattedSize + ' ' + sizes[i]
}
