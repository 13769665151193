
import { useWindowSizeFn } from '@/hooks/useWindowSizeFn'
import { OpenUrlEnum } from '@/types/url-enum'
import { defineComponent, reactive, toRefs } from 'vue'
import { event } from 'vue-gtag'

interface IMDownloadState {
  imgMaxWidth: string
}
export default defineComponent({
  name: 'MDownload',
  setup() {
    const state = reactive<IMDownloadState>({
      imgMaxWidth: '165px'
    })
    const onClickToPath = (type: string, toPath: string) => {
      // 上报google analytics统计 --- apple store || google play 跳转
      if (type === 'apple_store') {
        // 跳转去苹果商店
        event('web_home_jump_ios', {
          event_category: 'web_home',
          to_path: toPath,
          jump_source: type
        })
      } else if (type === 'google_play') {
        // 跳转去google Play
        event('web_home_jump_android', {
          event_category: 'web_home',
          to_path: toPath,
          jump_source: type
        })
      }
    }

    useWindowSizeFn(
      () => {
        state.imgMaxWidth = window.innerWidth < 725 ? '135px' : '165px'
      },
      200,
      { immediate: true }
    )

    return {
      OpenUrlEnum,
      ...toRefs(state),
      onClickToPath
    }
  }
})
