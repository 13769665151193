export default {
  2: '參數有誤',
  2000: '參數有誤',
  2001: '場景不存在',
  2002: '場景已下架',
  9001: '伺服器出錯，請重試',
  10004: '用戶登入失敗',
  10007: '帳號或密碼錯誤',
  10008: '用戶不存在',
  10010: '郵件內容發送失敗',
  10019: '郵箱修改密碼記錄不存在',
  10020: '重置密碼連結已過期',
  10021: '密碼連結已被重置',
  10022: '重置密碼失敗',
  10025: '密碼為空',
  10030: '在其他設備上登入',
  10037: 'token已過期',
  10039: '找不到該邀請碼信息',
  10040: '獲取邀請碼信息失敗',
  10043: '已經退出登錄',
  10046: '訂單不存在',
  10047: '創建訂單失敗',
  10048: '訂單配置不存在',
  10064: '用戶被封禁',
  10091: '未支付訂單數已超過20',
  70000: '參數有誤',
  70001: '充值信息不存在或已下架'
}
