export default {
  2: 'พารามิเตอร์เกิดข้อผิดพลาด',
  2000: 'พารามิเตอร์เกิดข้อผิดพลาด',
  2001: 'ไม่พบฉาก',
  2002: 'ฉากนี้ถูกถอดออกแล้ว',
  9001: 'เซิร์ฟเวอร์ไม่ตอบสนอง โปรดรอแล้วลองใหม่อีกครั้ง',
  10004: 'ล็อกอินผู้ใช้ไม่สำเร็จ',
  10007: 'บัญชีหรือรหัสผ่านไม่ถูกต้อง',
  10008: 'ไม่พบผู้ใช้',
  10010: 'ส่งเนื้อหาอีเมลไม่สำเร็จ',
  10019: 'ไม่พบบันทึกการเปลี่ยนรหัสผ่านอีเมล',
  10020: 'ลิงก์รีเซ็ตรหัสหมดอายุแล้ว',
  10021: 'ลิงก์รหัสผ่านนี้ถูกรีเซ็ตแล้ว',
  10022: 'รีเซ็ตรหัสผ่านไม่สำเร็จ',
  10025: 'รหัสผ่านว่างเปล่า',
  10030: 'ล็อกอินในอุปกรณ์เครื่องอื่น',
  10037: 'โทเคนหมดอายุแล้ว',
  10039: 'ไม่พบข้อมูลรหัสเชิญ',
  10040: 'รับรหัสเชิญไม่สำเร็จ',
  10043: 'ล็อกเอ้าท์แล้ว',
  10046: 'ไม่พบคำสั่งซื้อ',
  10047: 'สร้างคำสั่งซื้อไม่สำเร็จ',
  10048: 'ไม่พบการกำหนดค่าคำสั่งซื้อ',
  10064: 'ผู้ใช้ถูกแบน',
  10091: 'จำนวนคำสั่งซื้อที่ค้างชำระเกิน 20 รายการ',
  70000: 'พารามิเตอร์เกิดข้อผิดพลาด',
  70001: 'ไม่มีข้อมูลการเติมเงินหรือรายการถูกนำออกแล้ว'
}
