import Cookies from 'js-cookie'

// tokenKey
const tokenKey = 'weelife-home_token'
export const getToken = () => Cookies.get(tokenKey)
export const setToken = (token: string) => Cookies.set(tokenKey, token)
export const removeToken = () => Cookies.remove(tokenKey)

// User
const userInfoKey = 'weelife-home_userInfo'
export const getUserInfo = () => Cookies.get(userInfoKey)
export const setUserInfo = (userInfo: string) => Cookies.set(userInfoKey, userInfo)
export const removeUserInfo = () => Cookies.remove(userInfoKey)

// uid
const uidKey = 'weelife-home_uid'
export const getUid = () => Cookies.get(uidKey)
export const setUid = (uid: string) => Cookies.set(uidKey, uid)
export const removeUid = () => Cookies.remove(uidKey)

// lang
const langKey = 'weelife-home_lang'
export const getLang = () => Cookies.get(langKey)
export const setLang = (uid: string) => Cookies.set(langKey, uid)
export const removeLang = () => Cookies.remove(langKey)

// payOrder
const payOrderKey = 'weelife-home_payOrder'
export const getPayOrder = () => Cookies.get(payOrderKey)
export const setPayOrder = (info: string) => Cookies.set(payOrderKey, info)
export const removePayOrder = () => Cookies.remove(payOrderKey)
