<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" :width="width" :height="height"
    viewBox="0 0 36 36" version="1.1">
    <title>icon_copy</title>
    <g id="网站" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="落地页-房间码固底" transform="translate(-484.000000, -1454.000000)" stroke="#6667AB" stroke-width="4">
        <g id="编组-2" transform="translate(0.000000, 1398.000000)">
          <g id="ic_icon_coppy" transform="translate(484.000000, 56.000000)">
            <rect id="矩形" fill="#6667AB" x="5" y="15" width="16" height="16" rx="2" />
            <path
              d="M14,8.5 L14,8 C14,5.790861 15.790861,4 18,4 L28,4 C30.209139,4 32,5.790861 32,8 L32,18 C32,20.209139 30.209139,22 28,22 L27.5,22 L27.5,22"
              id="路径-13" stroke-linecap="round" stroke-linejoin="round" />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script lang='ts'>
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'MSvgCopy',
  props: {
    colors: {
      type: Array as PropType<string[]>,
      default: () => {
        return []
      }
    },
    width: {
      type: String,
      default: '48'
    },
    height: {
      type: String,
      default: '48'
    }
  }
})
</script>
<style lang='scss' scoped>
</style>
