<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(-380.000000, -734.000000)" :fill="colors[0]">
        <g transform="translate(0.000000, 551.000000)">
          <g transform="translate(260.000000, 175.000000)">
            <g transform="translate(120.000000, 8.000000)">
              <path
                d="M11.9998339,20.4549097 L6.78904794,22.6523442 C5.77127663,23.0815474 4.59827206,22.6044186 4.16906891,21.5866473 C4.03768532,21.2750969 3.98743272,20.9353227 4.02300758,20.5990791 L4.58379885,15.2986362 L4.58379885,15.2986362 L0.944813226,11.4012029 C0.1909924,10.5938443 0.234393359,9.32825828 1.04175198,8.57443746 C1.30986115,8.32410722 1.6416503,8.15233102 2.00082655,8.07789866 L7.41647216,6.95561047 L7.41647216,6.95561047 L10.291387,2.23202759 C10.8656595,1.28847846 12.0960969,0.98912055 13.0396461,1.56339298 C13.3127657,1.72962179 13.5420519,1.958908 13.7082807,2.23202759 L16.5831955,6.95561047 L16.5831955,6.95561047 L21.9988412,8.07789866 C23.0804306,8.30203723 23.7755318,9.3605389 23.5513933,10.4421283 C23.4769609,10.8013046 23.3051847,11.1330937 23.0548545,11.4012029 L19.4158689,15.2986362 L19.4158689,15.2986362 L19.9766601,20.5990791 C20.0928759,21.6975178 19.2966268,22.6821898 18.198188,22.7984055 C17.8619444,22.8339804 17.5221702,22.7837278 17.2106198,22.6523442 L11.9998339,20.4549097 L11.9998339,20.4549097 Z"
                id="Star-Copy-2"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'YbSvgStar',
  props: {
    colors: {
      type: Array as PropType<string[]>,
      default: () => {
        return []
      }
    },
    width: {
      type: String,
      default: '24'
    },
    height: {
      type: String,
      default: '24'
    }
  }
})
</script>
