export default {
  2: 'Hindi wastong kredensyal',
  2000: 'Hindi wastong kredensyal',
  2001: 'Walang ganoong senaryo',
  2002: 'Ang senaryo ay tinanggal na',
  9001: 'Ang server ay hindi tumutugon, mangyaring subukan muli',
  10004: 'Nabigong mag-log in ang gumagamit',
  10007: 'Mali ang account o password',
  10008: 'Ang gumagamit ay hindi umiiral',
  10010: 'Nabigong magpadala ng nilalaman ng email',
  10019: 'Walang rekord ng pagbabago ng password para sa mailbox na ito',
  10020: 'Ang koneksyon sa pag-reset ng password ay expired na',
  10021: 'Na-reset na ang koneksyon sa password',
  10022: 'Nabigong i-reset ang password',
  10025: 'Walang laman ang password',
  10030: 'Nag-log in sa ibang mga device',
  10037: 'Ang token ay nag-expire na',
  10039: 'Hindi mahanap ang impormasyon ng invitation code',
  10040: 'Nabigong makuha ang impormasyon ng invitation code',
  10043: 'Nakalog out na',
  10046: 'Walang ganoong order',
  10047: 'Nabigong lumikha ng order',
  10048: 'Walang ganoong order configuration',
  10064: 'Ang gumagamit ay naka-block',
  10091: 'Ang bilang ng mga hindi nabayarang order ay lumampas sa 20',
  70000: 'Hindi wastong kredensyal',
  70001: 'Walang ganoong impormasyon sa pag-recharge o tinanggal na'
}
