import * as Sentry from '@sentry/vue'

function initReportLimitStorage() {
  if (!sessionStorage.getItem('sentry_report_limit')) {
    console.log('此会话找不到sentry上报限制相关内容，将会自动初始化~')
    const reportLimit = {
      VIDEO_EXIST_URI: [],
      IMG_EXIST_URI: [],
      AUDIO_EXIST_URI: [],
      SCRIPT_EXIST_URI: [],
      STYLE_EXIST_URI: []
    }
    sessionStorage.setItem('sentry_report_limit', JSON.stringify(reportLimit))
  }
  const reportLimit: any = sessionStorage.getItem('sentry_report_limit')
  return JSON.parse(reportLimit)
}

function initSentryConfig() {
  const dsn = 'https://b5e952e5bcc94094b407628bf918d4b2@sentry.evkland.cn/2'
  let tracingOrigins: any = []
  let env = 'none'

  if (process.env.VUE_APP_ENV === 'development') {
    tracingOrigins = ['localhost', 'dev-meta-api.evkland.cn', /^\//]
    env = process.env.VUE_APP_ENV
  } else if (process.env.VUE_APP_ENV === 'stable') {
    if (process.env.VUE_APP_NAME === 'me') {
      tracingOrigins = ['localhost', 'stable-me-meta-api.evkland.cn', /^\//]
      env = process.env.VUE_APP_ENV + '-me'
    } else {
      tracingOrigins = ['localhost', 'stable-meta-api.evkland.cn', /^\//]
      env = process.env.VUE_APP_ENV
    }
  } else if (process.env.VUE_APP_ENV === 'production') {
    if (process.env.VUE_APP_NAME === 'me') {
      tracingOrigins = ['localhost', 'api-me.weelifeapp.com', /^\//]
      env = process.env.VUE_APP_ENV + '-me'
    } else {
      tracingOrigins = ['localhost', 'api.weelifeapp.com', /^\//]
      env = process.env.VUE_APP_ENV
    }
  }

  return {
    dsn,
    env,
    tracingOrigins
  }
}

export function startMonitor({ app, router }: any) {
  const { dsn, env, tracingOrigins } = initSentryConfig()

  if (dsn && tracingOrigins?.length) {
    // 初始化Sentry监控
    Sentry.init({
      app,
      dsn, // 后续根据环境变量来变动
      integrations: [
        new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          tracingOrigins
        })
      ],
      tracesSampleRate: 1.0,
      environment: env
    })

    // 添加全局unhandledrejection事件监听
    window.addEventListener('unhandledrejection', (event) => {
      Sentry.captureException({ sourceType: 'promise', message: 'promise异步错误', event })
    })
    // vue的全局错误监听
    app.config.errorHandler = (err: any) => {
      Sentry.captureException({ sourceType: 'vue', message: 'Vue全局监听检测到错误', event: err })
    }
    // window的error监听
    window.addEventListener('error', (event: any) => {
      const reportLimit = initReportLimitStorage()
      const currentURI = event?.target?.baseURI
      let errorInfo: any = { sourceType: 'error', message: '检测到异常', event }
      // 这里的资源加载监听是兜底，最好在资源的onerror里添加监听上报
      if (event?.target?.tagName === 'VIDEO' || (event?.target?.tagName === 'SOURCE' && event?.target?.parentElement?.tagName === 'VIDEO')) {
        const isExist = reportLimit.VIDEO_EXIST_URI.includes(currentURI)
        if (!isExist) {
          errorInfo = { soureType: 'video error', message: '视频资源加载异常', event }
          Sentry.captureException(errorInfo)
          reportLimit.VIDEO_EXIST_URI.push(currentURI)
        }
      } else if (event?.target?.tagName === 'IMG') {
        const isExist = reportLimit.IMG_EXIST_URI.includes(currentURI)
        if (!isExist) {
          errorInfo = { soureType: 'image error', message: '图片资源加载异常', event }
          Sentry.captureException(errorInfo)
          reportLimit.IMG_EXIST_URI.push(currentURI)
        }
      } else if (event?.target?.tagName === 'AUDIO' || (event?.target?.tagName === 'SOURCE' && event?.target?.parentElement?.tagName === 'AUDIO')) {
        const isExist = reportLimit.AUDIO_EXIST_URI.includes(currentURI)
        if (!isExist) {
          errorInfo = { soureType: 'audio error', message: '音频资源加载异常', event }
          Sentry.captureException(errorInfo)
          reportLimit.AUDIO_EXIST_URI.push(currentURI)
        }
      } else if (event?.target?.tagName === 'SCRIPT') {
        const isExist = reportLimit.SCRIPT_EXIST_URI.includes(currentURI)
        if (!isExist) {
          errorInfo = { soureType: 'script error', message: '脚本资源加载异常', event }
          Sentry.captureException(errorInfo)
          reportLimit.SCRIPT_EXIST_URI.push(currentURI)
        }
      } else if (event?.target?.tagName === 'STYLE') {
        const isExist = reportLimit.STYLE_EXIST_URI.includes(currentURI)
        if (!isExist) {
          errorInfo = { soureType: 'style error', message: '样式资源加载异常', event }
          Sentry.captureException(errorInfo)
          reportLimit.STYLE_EXIST_URI.push(currentURI)
        }
      } else {
        Sentry.captureException(errorInfo)
      }
      sessionStorage.setItem('sentry_report_limit', JSON.stringify(reportLimit))
    }, true)
  }
}
