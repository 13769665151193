import { getAliOssConfig } from '@/api/request/aliOss'
import { OssConfigType } from '@/api/types/aliOss'
import { ref } from 'vue'

export function useOss() {
  const ossConfig = ref<OssConfigType>()
  const refreshSTSTokenInterval = ref<number>(3600000)

  const getAilOssConfig = (fileType: number) => {
    return new Promise((resolve: (value: OssConfigType) => void, reject) => {
      getAliOssConfig(fileType).then(res => {
        ossConfig.value = res.data.credentials
        resolve(res.data.credentials)
      }).catch(err => {
        ossConfig.value = {} as OssConfigType
        reject(err)
        console.error('AilOssConfig', err)
      })
    })
  }

  return { getAilOssConfig, ossConfig, refreshSTSTokenInterval }
}
