
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'YbSvgPlay',
  props: {
    colors: {
      type: Array as PropType<string[]>,
      default: () => {
        return []
      }
    },
    width: {
      type: String,
      default: '80'
    },
    height: {
      type: String,
      default: '80'
    }
  }
})
