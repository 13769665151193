import { multipartUpload, multipartUploadOption } from '@/api/request/aliOss'
import { ref, watch } from 'vue'
import { useOss } from './useOss'
import { OssConfigType, OssMultipartUploadResult } from '@/api/types/aliOss'
import { filter } from 'lodash'

export interface ossUploadQueueListType {
  uid: number
  name: string
  status: string
}

const { getAilOssConfig, ossConfig, refreshSTSTokenInterval } = useOss()

/** 储存上传文件列表 */
const ossUploadQueueList = ref<ossUploadQueueListType[]>([])
const uploadTasks = new Map<number, { uploadId: string, name: string, config: OssConfigType, status: string }>()

export function useUpload() {
  const progressNum = ref<number>(0)
  const uploadStatus = ref<string>('')
  /** 存储上传中文件列表 */
  const uploadQueueList = ref<ossUploadQueueListType[]>([])

  /** 上传文件队列操作 */
  const uploadQueue = (val: ossUploadQueueListType) => {
    const existingIndex = ossUploadQueueList.value.findIndex(item => item.uid === val.uid && item.name === val.name)
    if (existingIndex === -1) {
      ossUploadQueueList.value.push(val)
    } else {
      ossUploadQueueList.value[existingIndex].status = val.status
    }
  }

  /**
   * 取消指定文件的上传
   * @param uid 文件的唯一标识
   */
  function aliCancelUpload(uid: number) {
    const task = uploadTasks.get(uid)
    if (task) {
      task.status = 'cancelled'
      uploadQueue({ uid, name: task.name, status: 'cancelled' })
    } else {
      console.warn(`No upload task found for file UID: ${uid}`)
    }
  }

  /**
   * 使用阿里oss分片上传
   * @param fileType 文件类型
   * @param file 文件
   * @returns
   */
  async function aliMultipartUpload(fileType: number, file: File, option?: Partial<multipartUploadOption>) {
    const webkitRelativeFolder = file.webkitRelativePath ? file.webkitRelativePath.split('/').length > 0 ? file.webkitRelativePath.split('/')[0] : '' : ''
    uploadStatus.value = 'start'
    uploadQueue({ uid: file.uid, name: file.name, status: uploadStatus.value })

    if (!option?.ossConfig) {
      await getAilOssConfig(fileType)
    }
    if (!ossConfig.value && !option?.ossConfig) {
      uploadStatus.value = 'error'
      uploadQueue({ uid: file.uid, name: file.name, status: uploadStatus.value })
      return Promise.reject(new Error('OSS凭证不存在'))
    }

    const config = Object.assign({}, option?.ossConfig || ossConfig.value, {
      cname: false,
      refreshSTSTokenInterval
    })

    uploadStatus.value = 'loading'

    return new Promise((resolve: (value: OssMultipartUploadResult) => void, reject) => {
      multipartUpload(config, file, (progress, checkpoint) => {
        const task = uploadTasks.get(file.uid)
        if (task && task.status === 'cancelled') {
          return reject(new Error(`Upload cancelled for UID ${file.uid}`))
        }
        if (!checkpoint) {
          checkpoint = { uploadId: `tmp-upload-id-${file.uid}`, name: file.name } // 临时生成一个 uploadId
        }
        if (!task && checkpoint) {
          uploadTasks.set(file.uid, { uploadId: checkpoint.uploadId, name: checkpoint.name, config, status: 'loading' })
        }
        progressNum.value = Number((progress * 100).toFixed(2))
        if (option?.onProgress) option.onProgress(progressNum.value)
      }, { destination: option?.destination }).then(res => {
        uploadStatus.value = 'success'
        uploadQueue({ uid: file.uid, name: file.name, status: uploadStatus.value })
        const result = Object.assign({ webkitRelativeFolder: webkitRelativeFolder }, res)
        progressNum.value = 0
        uploadTasks.delete(file.uid)
        resolve(result)
      }).catch(err => {
        if (uploadStatus.value !== 'cancelled') {
          uploadStatus.value = 'error'
          progressNum.value = 0
          uploadQueue({ uid: file.uid, name: file.name, status: uploadStatus.value })
        }
        reject(err)
      })
    })
  }

  watch(() => ossUploadQueueList.value, (val) => {
    if (!val.length) {
      uploadQueueList.value = []
      return
    }
    uploadQueueList.value = filter(val, ['status', 'start'])
    if (!uploadQueueList.value.length) {
      ossUploadQueueList.value = []
    }
  }, { deep: true })

  return { aliMultipartUpload, progressNum, uploadStatus, uploadQueueList, aliCancelUpload }
}
