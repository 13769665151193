
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'MLayout',
  props: {
    horizontal: {
      type: Boolean,
      default: false
    }
  }
})
