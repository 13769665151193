<template>
  <div class="yb-card" ph30 pv20>
    <slot></slot>
  </div>
</template>

<style lang="scss" scoped>
@include b(yb-card) {
  background-color: $background-color-secondary;
}
</style>
