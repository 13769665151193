export default {
  2: 'Parameter tidak betul',
  2000: 'Parameter tidak betul',
  2001: 'Adegan itu tidak wujud',
  2002: 'Tempat kejadian telah dinyahaktifkan',
  9001: 'Pelayan sepi, sila cuba lagi',
  10004: 'Pengguna gagal log masuk',
  10007: 'Nombor akaun atau kata laluan tidak betul',
  10008: 'Pengguna tidak wujud',
  10010: 'Gagal menghantar kandungan peti mel',
  10019: 'Rekod perubahan kata laluan e-mel tidak wujud',
  10020: 'Sambungan set semula kata laluan telah tamat tempoh',
  10021: 'Sambungan kata laluan telah ditetapkan semula',
  10022: 'Gagal menetapkan semula kata laluan',
  10025: 'Kata laluan kosong',
  10030: 'Log masuk pada peranti lain',
  10037: 'Token telah tamat tempoh',
  10039: 'Maklumat kod jemputan tidak dapat dijumpai',
  10040: 'Gagal mendapatkan maklumat kod jemputan',
  10043: 'Dilog keluar',
  10046: 'Perintah itu tidak wujud',
  10047: 'Gagal mencipta pesanan',
  10048: 'Konfigurasi pesanan tidak wujud',
  10064: 'Pengguna diharamkan',
  10091: 'Bilangan pesanan yang belum dibayar telah melebihi 20',
  70000: 'Parameter tidak betul',
  70001: 'Maklumat deposit tidak wujud atau telah dinyahaktifkan'
}
