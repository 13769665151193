import zh from './lang/zh'
import en from './lang/en'
import zhHant from './lang/zh-Hant'
import id from './lang/id'
import ms from './lang/ms'
import ar from './lang/ar'
import tl from './lang/tl'
import vi from './lang/vi'
import th from './lang/th'
import tr from './lang/tr'

// 定义错误消息的类型
export type ErrorMessagesType = {
  [key: string]: string
};

// 定义错误码 V2 的类型
export type ErrorCodeV2Type = {
  [key: string]: ErrorMessagesType
}

export const errorCodeV2: ErrorCodeV2Type = {
  en: {
    ...en
  },
  zh: {
    ...zh
  },
  'zh-cn': {
    ...zh
  },
  'zh-Hans': {
    ...zh
  },
  'zh-Hant': {
    ...zhHant
  },
  'zh-HK': {
    ...zhHant
  },
  'zh-TW': {
    ...zhHant
  },
  ms: {
    ...ms
  },
  'ms-bn': {
    ...ms
  },
  'ms-my': {
    ...ms
  },
  'ms-cn': {
    ...ms
  },
  'ms-sg': {
    ...ms
  },
  'ms-id': {
    ...ms
  },
  'ms-au': {
    ...ms
  },
  'ms-ph': {
    ...ms
  },
  id: {
    ...id
  },
  'id-bn': {
    ...id
  },
  'id-my': {
    ...id
  },
  'id-cn': {
    ...id
  },
  'id-sg': {
    ...id
  },
  'id-id': {
    ...id
  },
  'id-idr': {
    ...id
  },
  'id-idn': {
    ...id
  },
  'id-tl': {
    ...id
  },
  in: {
    ...id
  },
  ar: {
    ...ar
  },
  'ar-ae': {
    ...ar
  },
  'ar-eg': {
    ...ar
  },
  tl: {
    ...tl
  },
  fil: {
    ...tl
  },
  'tl-ph': {
    ...tl
  },
  'fil-ph': {
    ...tl
  },
  vi: {
    ...vi
  },
  'vi-vn': {
    ...vi
  },
  th: {
    ...th
  },
  'th-th': {
    ...th
  },
  tr: {
    ...tr
  },
  'tr-tr': {
    ...tr
  }
}
