import Vuex from 'vuex'
import { app } from './modules/app'
import { user } from './modules/users'
import getters from './getters'

const store = new Vuex.Store({
  modules: {
    app,
    user
  },
  getters
})

export default store
