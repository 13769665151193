
import { reactive, toRefs, PropType, defineComponent, watchEffect, watch, getCurrentInstance, ComponentInternalInstance } from 'vue'
import { IDropdownState, metaDropdownEmits } from './data'
import { Popup, Picker, Popover, Icon } from 'vant'
import { SelectTypeItem } from '@/types/common'
import { isMobile } from '@/utils/is'
import { useWindowSizeFn } from '@/hooks/useWindowSizeFn'

export default defineComponent({
  name: 'MetaDropdown',
  components: {
    [Popup.name as string]: Popup,
    [Picker.name as string]: Picker,
    [Icon.name as string]: Icon,
    [Popover.name as string]: Popover
  },
  emits: metaDropdownEmits,
  props: {
    modelValue: {
      type: [String, null] as PropType<string>,
      default: null
    },
    data: {
      type: Array as PropType<SelectTypeItem[]>,
      default: [] as SelectTypeItem[]
    },
    teleport: {
      type: String as PropType<string>,
      default: '#main'
    },
    placement: {
      type: String as PropType<string>,
      default: 'left-start'
    },
    textColor: {
      type: String as PropType<string>,
      default: '#262928'
    },
    zIndex: {
      type: [Number, String] as PropType<number | string>,
      default: 2006
    }
  },
  setup(props, { emit }) {
    const { slots } = getCurrentInstance() as ComponentInternalInstance
    const state = reactive<IDropdownState>({
      show: false,
      value: '',
      customFieldName: {
        text: 'label',
        value: 'value'
      },
      defaultIndex: 0,
      isMobile: false,
      isSlots: false
    })

    watchEffect(() => {
      state.value = props.modelValue
    })

    watch(() => state.show, () => {
      state.defaultIndex = props.data.findIndex((item) => item.value === state.value)
    }, { immediate: true })

    watch(() => slots?.content, (val) => {
      if (val) {
        state.isSlots = true
        console.log(state.isSlots)
      }
    }, { immediate: true })

    useWindowSizeFn(
      () => {
        state.isMobile = isMobile()
      },
      100,
      { immediate: true }
    )

    const handleSelect = (value: SelectTypeItem, index: number) => {
      state.defaultIndex = index
      handleConfirm(value)
    }

    const handleConfirm = (value: any) => {
      emit('update:modelValue', (value as SelectTypeItem).value)
      emit('onConfirm', value as SelectTypeItem)
      state.show = false
    }

    const handleCancel = () => {
      emit('onCancel')
      state.show = false
      state.defaultIndex = 0
    }
    return {
      ...toRefs(state),
      handleSelect,
      handleCancel,
      handleConfirm
    }
  }
})
